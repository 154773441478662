/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query LogsReadQuery($smppId: ID, $take: PositiveInt, $skip: Int) {
    LogsRead(vendorAccountId: $smppId, take: $take, skip: $skip) {
      id,
      createdAt,
      updatedAt,
      severity,
      output,
      status,
      message,
      _totalCount
    }
  }
`
