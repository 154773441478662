/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import Debounce from 'lodash/debounce' // Npm: Debounce library.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import KeywordDirectoryReadQuery from './__query__/index.keywordDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ keywordDirectoryValue, keywordValue, inValidKeyword, disabled, inValidKeywordDirectory, isRequired, onChange }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [keywordDirectory, setKeywordDirectory] = React.useState('')
  const [keywordDirectoryToSearch, setKeywordDirectoryToSearch] = React.useState('')
  const [keyword, setKeyword] = React.useState([])
  const _QueryKeywordDirectoryRead = useQuery(KeywordDirectoryReadQuery, { 'variables': { keywordDirectoryToSearch, 'take': _skipDifference, 'skip': 0 } })
  const _onKeywordInputChange = React.useCallback(Debounce(e => setKeywordDirectoryToSearch(e.target.value), 800), [])

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if keywordDirectory value
     * Is passed in params.
     */
    keywordDirectoryValue && _.isEmpty(keywordDirectory) && setKeywordDirectory(keywordDirectoryValue ?? '')
    keywordValue && _.isEmpty(keyword) && setKeyword(keywordValue ?? [])
  }, [keywordDirectoryValue, keywordValue])

  // Const assignment.
  const _selectedTarget = (keywordDirectory)?.split('(')[1]?.split(')')[0]
  const _selectedKeywordDirectory = _QueryKeywordDirectoryRead?.data?.KeywordDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedKeywordDirectory = _selectedKeywordDirectory?.[0]

  // Return the JSX.
  return (
    <Flex w='100%' className='keywordDirectoryAndKeywordSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='Keyword Folder'
        disabled={_QueryKeywordDirectoryRead?.loading || disabled}
        name='keywordDirectory'
        value={keywordDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryKeywordDirectoryRead?.data?.KeywordDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidKeywordDirectory}
        onChange={_onKeywordInputChange}
        onSelect={__event => {
          // Update states.
          setKeywordDirectory(__event.target.value)
          setKeyword([])

          // Return updated keywordDirectory and keyword.
          onChange({ 'keyword': [], 'keywordDirectory': __event.target.value })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Keywords{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedKeywordDirectory) || _QueryKeywordDirectoryRead?.loading || 0 === _firstSelectedKeywordDirectory?.Keyword?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_firstSelectedKeywordDirectory) || _QueryKeywordDirectoryRead?.loading || disabled || 0 === _firstSelectedKeywordDirectory?.Keyword?.length}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidKeyword ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidKeyword ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(keyword) && !_.isEmpty(keyword) ? [keyword] : keyword)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_QueryKeywordDirectoryRead?.data?.KeywordDirectoryRead?.map?.(item => item?.id?.includes(_selectedTarget) ? item?.Keyword?.map?.(r => _.isEmpty(r?.keyword) ? void 0 : (
              <MenuItem closeOnSelect={false} key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedKeywordDirectory.length}
                  name='keyword'
                  onChange={() => {
                    // Update state of keywords.
                    setKeyword(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r.keyword} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r.keyword} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'keyword': _data, 'keywordDirectory': keywordDirectory })

                        // Return updated keyword.
                        return _data
                      }

                      // Return updated keyword.
                      onChange({ 'keyword': [...j, `${r.keyword} (${r?.id})`], 'keywordDirectory': keywordDirectory })

                      // Return updated keyword and id.
                      return [...j, `${r.keyword} (${r?.id})`]
                    })
                  }}
                  isChecked={keyword.includes(`${r.keyword} (${r?.id})`)}>
                  {`${r.keyword} (${r?.id})`}
                </Checkbox>
              </MenuItem>
            )) : void 0)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _keywordIds = []

                /*
                 * Loop over the list of id directory
                 * and get all child ids.
                 */
                for (const item of _QueryKeywordDirectoryRead.data.KeywordDirectoryRead) {
                  // Only proceed if given item is selected one.
                  if (item?.id?.includes?.(_selectedTarget)) {
                    // Loop over item child container.
                    for (const _mac of item.Keyword) {
                      // Push id to container.
                      _keywordIds.push(`${_mac.keyword} (${_mac.id})`)
                    }
                  }
                }

                // Update keyword.
                setKeyword(_keywordIds)

                // Return updated keyword.
                onChange({ 'keyword': _keywordIds, 'keywordDirectory': keywordDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {keyword?.length === _firstSelectedKeywordDirectory?.keyword?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update states.
                setKeyword([])

                // Return updated mcc and mnc.
                onChange({ 'keyword': [], 'keywordDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === keyword?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'keywordDirectoryValue': PropTypes.string,
  'keywordIdValue': PropTypes.array,
  'inValidKeywordId': PropTypes.bool,
  'keywordValue': PropTypes.array,
  'inValidKeyword': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidKeywordDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index



