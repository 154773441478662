/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import MccMncSelector from 'components/MccMncSelector'
import SenderIdsDirectorySelector from 'components/SenderIdsDirectorySelector'
import PhoneNumberoDirectorySelector from 'components/PhoneNumberoDirectorySelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RatePlanReadQuery from './__query__/index.ratePlan.read.query'
import RatePlanUpdateMutation from './__mutation__/index.ratePlan.update.mutation'
import RatePlanCreateMutation from './__mutation__/index.ratePlan.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [showPhoneNumberBasedRate, setShowPhoneNumberBasedRate] = React.useState('')
  const [showSenderIdBasedRate, setShowSenderIdBasedRate] = React.useState('')
  const [QueryRatePlanRead, QueryRatePlanReadResponse] = useLazyQuery(RatePlanReadQuery, { 'variables': { 'ratePlanId': passOn?.ratePlanId } })
  const [MutationRatePlanCreate, MutationRatePlanCreateResponse] = useMutation(RatePlanCreateMutation)
  const [MutationRatePlanUpdate, MutationRatePlanUpdateResponse] = useMutation(RatePlanUpdateMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Const assignment.
    let _mcc, _mnc, _phoneNumbero, _phoneNumberoDirectory, _senderIdDirectoryId, _senderIds

    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'mcc': JoiBrowser.string(),
      'mnc': JoiBrowser.array().items(JoiBrowser.string().invalid('')),
      'rate': JoiBrowser.number(),
      'smsType': JoiBrowser.string().optional(),
      'senderIdBasedRate': JoiBrowser.number(),
      'phoneNumberoBasedRate': JoiBrowser.number()
    }).options({ 'allowUnknown': true })

    /*
     * Report failure if _formDataRef.current?.phoneNumbero is defined
     * but its rate is not defined.
     */
    if (_formDataRef.current?.senderIdDirectoryId && !_formDataRef.current?.senderIdBasedRate) return setError('senderIdBasedRate is required.')
    if (_formDataRef.current?.phoneNumberoDirectory && !_formDataRef.current?.phoneNumberoBasedRate) return setError('phoneNumberoBasedRate is required.')

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Make sure that mnc is array.
    if (!isCreateOnly && !_.isArray(_formDataRef.current?.mnc)) _formDataRef.current = { ..._formDataRef.current, 'mnc': [(_formDataRef.current?.mnc)?.split('(')[1]?.split(')')[0]] }

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with ratePlanId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.ratePlanId)) _formDataRef.current = { ..._formDataRef.current, 'ratePlanId': passOn?.ratePlanId }
    if (isCreateOnly && _.isEmpty(_formDataRef.current?.rateId)) _formDataRef.current = { ..._formDataRef.current, 'rateId': passOn?.rateId }

    // Variable assignment.
    _phoneNumbero = _formDataRef.current?.phoneNumbero
    _phoneNumberoDirectory = _formDataRef.current?.phoneNumberoDirectory
    _senderIdDirectoryId = _formDataRef.current?.senderIdDirectoryId
    _senderIds = _formDataRef.current?.senderId
    _mcc = _formDataRef.current?.mcc
    _mnc = _formDataRef.current?.mnc

    // Conditionally update senderId and senderIdDirectoryId.
    if (_formDataRef.current?.senderIdDirectoryId && (_formDataRef.current?.senderIdDirectoryId?.includes('(') || _formDataRef.current?.senderIdDirectoryId?.includes(')'))) _senderIdDirectoryId = _formDataRef.current?.senderIdDirectoryId.split('(')[1].split(')')[0]
    if (_formDataRef.current?.senderId && 0 < _formDataRef.current?.senderId?.length) _senderIds = _.compact(_.flatten(_formDataRef.current?.senderId.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
    if (_formDataRef.current?.phoneNumberoDirectory && (_formDataRef.current?.phoneNumberoDirectory?.includes('(') || _formDataRef.current?.phoneNumberoDirectory?.includes(')'))) _phoneNumberoDirectory = _formDataRef.current?.phoneNumberoDirectory.split('(')[1].split(')')[0]
    if (_formDataRef.current?.phoneNumbero && 0 < _formDataRef.current?.phoneNumbero?.length) _phoneNumbero = _.compact(_.flatten(_formDataRef.current?.phoneNumbero.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
    if (_formDataRef.current?.mcc && (_formDataRef.current?.mcc?.includes('(') || _formDataRef.current?.mcc?.includes(')'))) _mcc = _formDataRef.current.mcc.split('(')[1].split(')')[0]
    if (_formDataRef.current?.mnc && 0 < _formDataRef.current?.mnc?.length) _mnc = _.compact(_.flatten(_formDataRef.current?.mnc.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

    /*
     * Update _formDataRef with input and ratePlan
     * if it doesn't exist. Also make sure that mode is
     * not create only.
     */
    if (_.isEmpty(_formDataRef.current?.input)) {
      // Update _formDataRef with input.
      _formDataRef.current.input = [
        {
          ..._.pick(_formDataRef.current, 'rate', 'smsType'),
          'mcc': _mcc,
          'mnc': _mnc,
          'rateId': isCreateOnly ? passOn?.rateId : void 0,
          'senderIdDirectoryId': _senderIdDirectoryId,
          'senderId': _senderIds,
          'phoneNumberoDirectoryId': _phoneNumberoDirectory,
          'phoneNumbero': _phoneNumbero,
          'phoneNumberoBasedRate': _formDataRef.current?.phoneNumberoBasedRate,
          'senderIdBasedRate': _formDataRef.current?.senderIdBasedRate
        }
      ]
    }

    // Execute update mutation.
    const _MutationRatePlanUpdate = await [isCreateOnly ? MutationRatePlanCreate : MutationRatePlanUpdate]?.[0]({ 'variables': { 'ratePlanId': isCreateOnly ? '' : passOn?.ratePlanId, 'ratePlanIds': passOn?.ratePlanIds, 'input': _formDataRef.current.input } })

    // If mutation caught an exception then report failure.
    if (_MutationRatePlanUpdate instanceof Error) return _MutationRatePlanUpdate

    // Check if create was successful.
    if (!_.isEmpty(_MutationRatePlanUpdate?.data?.RatePlanUpdate) && _.every(_.pluck(_MutationRatePlanUpdate?.data?.RatePlanUpdate, 'status') ?? [], j => 'UPDATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the rate plan.')

      // Report failure.
      return onClose?.()
    }
    if (!_.isEmpty(_MutationRatePlanUpdate?.data?.RatePlanCreate) && _.every(_.pluck(_MutationRatePlanUpdate?.data?.RatePlanCreate, 'status') ?? [], j => 'CREATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the rate plan.')

      // On successful create close modal.
      return onClose?.()
    }

    // Style Guide.
    toast(_.isEmpty(_MutationRatePlanUpdate?.data?.RatePlanCreate) ? _.pluck(_MutationRatePlanUpdate?.data?.RatePlanUpdate, 'message')?.join(' ') : _.pluck(_MutationRatePlanUpdate?.data?.RatePlanCreate, 'message')?.join(' '))

    // Report failure.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRatePlanReadQuery = await QueryRatePlanRead({ 'variables': { 'ratePlanId': isCreateOnly ? 'UN_KNOWN' : passOn?.ratePlanId } })

      // If query caught an exception then report failure.
      if (_QueryRatePlanReadQuery instanceof Error) return _QueryRatePlanReadQuery

      /*
       * If ratePlan details fetch complete then
       * update its value.
       */
      if (_QueryRatePlanReadQuery?.data?.RatePlanRead && _.every(_QueryRatePlanReadQuery?.data?.RatePlanRead, j => 'READ_SUCCESSFUL' === j.status)) {
        // Update form data.
        _formDataRef.current = {
          'ratePlanId': passOn?.ratePlanId,
          'createdAt': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.createdAt,
          'updatedAt': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.updatedAt,
          'mcc': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.Mcc?.mcc,
          'senderId': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.SenderId?.map?.(j => `${j?.senderId} (${j?.id})`),
          'senderIdDirectoryId': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.SenderIdDirectory?.displayName ? `${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.SenderIdDirectory?.displayName} (${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.SenderIdDirectory?.id})` : void 0,
          'phoneNumbero': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.PhoneNumbero?.map?.(j => `${j?.phoneNumbero} (${j?.id})`),
          'phoneNumberoDirectory': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.PhoneNumberoDirectory?.displayName ? `${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.PhoneNumberoDirectory?.displayName} (${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.PhoneNumberoDirectory?.id})` : void 0,
          'countryName': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.Mcc?.countryName,
          'mnc': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.Mnc?.mnc ? `(${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.Mnc?.mnc}) ${_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.Mnc?.network}` : '',
          'smsType': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.smsType,
          'rate': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.rate,
          'senderIdBasedRate': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.senderIdBasedRate,
          'phoneNumberoBasedRate': _.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.phoneNumberoBasedRate
        }

        // Show senderIdBasedRate if senderId is selected.
        !_.isEmpty(_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.SenderIdDirectory) && setShowSenderIdBasedRate(0 < _formDataRef.current?.senderIdBasedRate)

        // Show phoneNumberBasedRate if phoneNumbero is selected.
        !_.isEmpty(_.first(_QueryRatePlanReadQuery?.data?.RatePlanRead)?.PhoneNumberoDirectory) && setShowPhoneNumberBasedRate(0 < _formDataRef.current?.phoneNumberoBasedRate)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationRatePlanUpdateResponse.loading || MutationRatePlanCreateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryRatePlanReadResponse?.loading

  // Return component.
  return (
    <form className='ratePlanUpsert' onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex width='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MccMncSelector
            isRequired={true}
            disabled={_isInputDisabled}
            mccValue={_formDataRef?.current?.countryName && _formDataRef?.current?.mcc ? `${_formDataRef?.current?.countryName} (${_formDataRef?.current?.mcc})` : _formDataRef?.current?.mcc}
            mncValue={_formDataRef?.current?.mnc}
            inValidMnc={error?.includes('mnc')}
            inValidMcc={error?.includes('mcc')}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'mnc': i.mnc, 'mcc': i.mcc })
            }}
          />
          <SenderIdsDirectorySelector
            disabled={_isInputDisabled}
            senderIdDirectoryValue={_formDataRef?.current?.senderIdDirectoryId}
            senderIdValue={_formDataRef?.current?.senderId}
            inValidSenderId={error?.includes('senderId')}
            inValidSenderIdDirectory={error?.includes('senderIdDirectoryId')}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'senderId': i.senderId, 'senderIdDirectoryId': i.senderIdDirectory })

              // Show senderIdBasedRate if senderId is selected.
              setShowSenderIdBasedRate(0 < i.senderId.length)
            }}
          />
          <PhoneNumberoDirectorySelector
            disabled={_isInputDisabled}
            phoneNumberoDirectoryValue={_formDataRef?.current?.phoneNumberoDirectory}
            phoneNumberoValue={_formDataRef?.current?.phoneNumbero}
            inValidPhoneNumbero={error?.includes('phoneNumbero')}
            inValidPhoneNumberoDirectory={error?.includes('phoneNumberoDirectory')}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'phoneNumbero': i.phoneNumbero, 'phoneNumberoDirectory': i.phoneNumberoDirectory })

              // Show phoneNumberBasedRate if phoneNumbero is selected.
              setShowPhoneNumberBasedRate(0 < i.phoneNumbero.length)
            }}
          />
        </Flex>
        <Flex width='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          {['RESELLER', 'RETAIL'].includes(passOn.type) && (
            <MemoizedSelect
              disabled={_isInputDisabled}
              name='smsType'
              label='SMS Type'
              isRequired={true}
              placeholder='Enter SMS Type'
              onChange={i => (_formDataRef.current = { ..._formDataRef?.current, 'smsType': i.target.value })}
              error={error}
              isInvalid={error?.includes('smsType')}
              data={_formDataRef?.current?.smsType}
              options={Object.React.App.enums.SMS_TYPE.enums?.map(i => i.key)}
            />
          )}
          <MemoizedInput
            disabled={_isInputDisabled}
            name='rate'
            label='Rate'
            isRequired={true}
            placeholder='Enter rate'
            onChange={i => (_formDataRef.current = {
              ..._formDataRef?.current,
              'rate': parseFloat(i.target.value)
            })}
            error={error}
            min='1'
            isInvalid={error?.includes('rate')}
            data={_formDataRef?.current?.rate}
            type='number'
          />
        </Flex>
        {
          showSenderIdBasedRate && (
            <MemoizedInput
              disabled={_isInputDisabled}
              name='senderIdBasedRate'
              label='Sender Id Rate'
              isRequired={true}
              placeholder='Enter rate'
              onChange={i => (_formDataRef.current = {
                ..._formDataRef?.current,
                'senderIdBasedRate': parseFloat(i.target.value)
              })}
              error={error}
              isInvalid={error?.includes('senderIdBasedRate')}
              data={_formDataRef?.current?.senderIdBasedRate}
              type='number'
            />
          )
        }
        {
          showPhoneNumberBasedRate && (
            <MemoizedInput
              disabled={_isInputDisabled}
              name='phoneNumberoBasedRate'
              label='Phone Number Rate'
              isRequired={true}
              placeholder='Enter rate'
              onChange={i => (_formDataRef.current = {
                ..._formDataRef?.current,
                'phoneNumberoBasedRate': parseFloat(i.target.value)
              })}
              error={error}
              isInvalid={error?.includes('phoneNumberoBasedRate')}
              data={_formDataRef?.current?.phoneNumberoBasedRate}
              type='number'
            />
          )
        }
      </Flex>
      <SubmitButton
        disabled={_isInputDisabled}
        isLoading={_isLoading}
        onSubmit={_SubmitForm}
        defaultText={isCreateOnly ? 'Create RatePlan' : 'Update RatePlan'} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
