/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import PropTypes from 'prop-types' // Npm: Prop types.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import React from 'react' // Npm: react.js library.
import _ from 'underscore' // Npm: Utility library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { FixedSizeList as List } from 'react-window' // Npm: React Virtualized for virtualized list.
import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Thead, Tr, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { HiChatBubbleOvalLeftEllipsis, HiUserGroup } from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import CustomerAccountSelector from 'components/CustomerAccountSelector'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import TestReadQuery from './__query__/index.test.read.query'
import TestCustomerAccountMutation from './__mutation__/index.test.customerAccount.mutation'


/*
 * STYLES
 */
import './index.css'
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [error, setError] = React.useState('')
  const [testResults, setTestResults] = React.useState([])
  const [customerAccountId, setCustomerAccountId] = React.useState('')
  const [MutationTestCustomerAccount, MutationTestCustomerAccountResponse] = useMutation(TestCustomerAccountMutation)
  const [QueryTestRead, QueryTestReadResponse] = useLazyQuery(TestReadQuery, { 'variables': { 'customerAccountId': customerAccountId && (customerAccountId?.includes('(') || customerAccountId?.includes(')')) ? customerAccountId.split('(')[1].split(')')[0] : customerAccountId, 'take': _skipDifference, 'skip': 0 } })
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _formDataRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _SubmitForm = async () => {
    // Local variable.
    let _customerAccountId

    // Reset error.
    setError('')

    // Conditionally fix for customerAccountId.
    if (_formDataRef?.current?.customerAccountId && (_formDataRef?.current?.customerAccountId?.includes('(') || _formDataRef?.current?.customerAccountId?.includes(')'))) _customerAccountId = _formDataRef?.current?.customerAccountId.split('(')[1].split(')')[0]

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'customerAccountId': JoiBrowser.string().required(),
      'destinationAddress': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute update mutation.
    const _MutationTestCustomerAccount = await MutationTestCustomerAccount({ 'variables': { ..._formDataRef.current, 'customerAccountId': _customerAccountId } })

    // If mutation caught an exception then report failure.
    if (_MutationTestCustomerAccount instanceof Error) return _MutationTestCustomerAccount

    // Style guide.
    toast(_MutationTestCustomerAccount?.data?.TestCustomerAccount?.message)

    // Report void.
    return void 0
  }
  const _FetchData = async (__take, __skip) => {
    // Fetch data from given api.
    const _QueryTestRead = await QueryTestRead({ 'variables': { 'customerAccountId': customerAccountId && (customerAccountId?.includes('(') || customerAccountId?.includes(')')) ? customerAccountId.split('(')[1].split(')')[0] : customerAccountId, 'take': __take, 'skip': __skip } })

    // If query caught an exception then report failure.
    if (_QueryTestRead instanceof Error) return _QueryTestRead

    // Update test results.
    return setTestResults(r => [...r, ..._QueryTestRead?.data?.TestRead?.filter(e => !_.isEmpty(e.response?.SmppToDeliverSm))?.reverse() ?? []])
  }

  // Event handler.
  React.useEffect(() => {
    // If data is available then update.
    _FetchData(_skipDifference, 0)
  }, [customerAccountId])

  // Data assignment.
  const _isInputDisabled = MutationTestCustomerAccountResponse.loading

  // Component assignment.
  const _List = ({ index, style }) => (
    <Tr key={String.random(8)} style={style}>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {' '}
        {`${index + 1}.`}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_countryName ?? '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_routePlanDisplayName ?? '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_ratePlanDisplayName ?? '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_customerCost ?? '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_vendorCost ?? '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_vendorCost && _data?.[index]?.response?.SmppToDeliverSm?._patch_customerCost ? (_data?.[index]?.response?.SmppToDeliverSm?._patch_vendorCost - _data?.[index]?.response?.SmppToDeliverSm?._patch_customerCost) : '-'}
      </Td>
      <Td
        style={rowStyle}
        borderRight='1px solid rgba(216, 227, 252, 1)'>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_sourceAddress ?? '-'}
      </Td>
      <Td
        style={rowStyle}>
        {testResults?.[index]?.response?.SmppToDeliverSm?._patch_vendorAccount ?? '-'}
      </Td>
    </Tr>
  )

  // Update proptypes.
  _List.propTypes = {
    'index': PropTypes.number,
    'style': PropTypes.object
  }

  console.log('---wioewr', QueryTestReadResponse.loading && !_isFirstLoadCompleted.current)

  // Return component.
  return (
    <>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap={_isCurrentViewMobile ? '12px' : '22px'}
        height='100%'
        borderRadius='20px'
        p={_isCurrentViewMobile ? '12px' : '22px'}
        className='messageTesting'
        color='#1B2559'
        bg='linear-gradient(243.84deg, #8C33FC 22.73%, #4F27FF 59.05%)'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
        <Flex justifyContent='space-between' flexDirection={{ 'base': 'column', 'md': 'row' }}>
          <Flex my={_isCurrentViewMobile ? '12px' : void 0} flexDir='column' alignItems='center' justifyContent='center'>
            <Text as='h2' fontSize='2xl' color='white'>Message Testing</Text>
            <Text w={_isCurrentViewMobile ? '100%' : '50%'} as='p' mt='0' my='12px' color='gray.100' gap='6px' className='heading' fontSize='lg' textAlign='center'>
              Select <Text as='span' cursor='pointer' fontWeight={700} color='white.700'><HiUserGroup size={21} /> Customer Account</Text> and send
              <Text cursor='pointer' as='span' fontWeight={700} color='white.700'><HiChatBubbleOvalLeftEllipsis size={22} /> Message</Text> to test how message will going to behave.
            </Text>
          </Flex>
          <Flex w={{ 'base': '100%', 'md': '35%' }} flexDir='column' gap='22px'>
            <Flex w='100%'>
              <CustomerAccountSelector
                name='customerAccountId'
                label='Customer Account'
                placeholder='e.g. Spam Vendor'
                iconColor='brand.500'
                error={error}
                isInvalid={error?.includes('customerAccountId')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }

                  // Update customerAccount.
                  setCustomerAccountId(value)
                }}
                disabled={_isInputDisabled}
              />
            </Flex>
            <Flex flexDir='row' alignItems='center' justifyContent='space-between' position='relative'>
              <MemoizedInput
                w='100%'
                name='destinationAddress'
                label='Destination Address'
                placeholder='e.g. "8826668515"'
                error={error}
                isInvalid={error?.includes('destinationAddress')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                disabled={_isInputDisabled}
                isRequired />
              <Button
                color='white'
                bg='brand.500'
                zIndex={100}
                onClick={_SubmitForm}
                _hover={{ 'bg': 'brand.600' }}
                _focus={{ 'bg': 'brand.600' }}
                _active={{ 'bg': 'brand.600' }}
                position='absolute'
                right={0}
                bottom={0}
                borderRadius={12}
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}>
                Send
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Text as='h3' fontSize='md' color='white' fontWeight={600} mt={2}>Test Results</Text>
        <TableContainer
          flex={1}
          h='100%'
          minH='220px'
          display='flex'
          borderRadius='12px'
          bg='white'
          overflow='auto'
          outline='1px solid #C5CFE8'>
          <Table w='100%' h='100%' variant='simple' size='md'>
            <Thead ref={_tableHeaderHeightRef}>
              <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  S.No.
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Country
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Route Plan
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Rate Plan
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Cost
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Term. Cost
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Margin
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Sender Id
                </Td>
                <Td
                  style={rowStyle}>
                  Vendor Ac.
                </Td>
              </Tr>
            </Thead>
            {QueryTestReadResponse.loading && !_isFirstLoadCompleted.current && 0 === testResults?.length ? (
              <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : 0 === testResults?.length ? (
              <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : (
              <Tbody style={cellStyle} h='100%' w='100%'>
                <AutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      width={width}
                      height={height}
                      isItemLoaded={__index => Boolean(testResults[__index])}
                      itemCount={testResults.length}
                      threshold={0.5}
                      loadMoreItems={(__startIndex, __endIndex) => _FetchData(_skipDifference, parseInt(__endIndex, 10) + 1)}>
                      {({ onItemsRendered, ref }) => (
                        <List
                          ref={ref}
                          height={height}
                          itemCount={testResults.length}
                          itemSize={52}
                          onItemsRendered={onItemsRendered}
                          overflow='hidden'
                          width={width}>
                          {_List}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index



