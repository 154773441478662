/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerType: CUSTOMER_TYPE, $take: PositiveInt!, $skip: Int!) {
  CustomerAccountRead(type: $customerType, take: $take, skip: $skip) {
    id,
    displayName,
    message,
    status
  }
}
`
