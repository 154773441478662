/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiBarsArrowDown } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import VendorAccountSelector from 'components/VendorAccountSelector'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import LogsReadQuery from './__query__/index.logs.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'

/*
 * OBJECTS
 */
const Index = () => {
  // Local variable.
  let _queryLogsRead

  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [smppId, setSmppId] = React.useState('')
  const [debuggingLevel, setDebuggingLevel] = React.useState(['ALL'])
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const _tableHeaderHeightRef = React.useRef(0)
  const _paginationContainerRef = React.useRef(100)
  const _QueryLogsRead = useQuery(LogsReadQuery, {
    'variables': {
      smppId,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })

  // Data assignment.
  _queryLogsRead = _QueryLogsRead.data?.LogsRead || []

  // If debugging level is not empty.
  if (debuggingLevel) {
    // Filter logs based on debugging level.
    _queryLogsRead = _queryLogsRead.filter(i => debuggingLevel?.includes('ALL') ? i : debuggingLevel?.includes(i?.severity))
  }

  // OrderBy _queryLogsRead by createdAt.
  _queryLogsRead = _.sortBy(_queryLogsRead, i => i?.createdAt)?.reverse()

  // Return component.
  return (
    <>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='22px'
        bg='white'
        h={`calc(100% - ${_paginationContainerRef?.current?.clientHeight + 12}px)`}
        minH={`calc(100% - ${_paginationContainerRef?.current?.clientHeight + 12}px)`}
        maxH={`calc(100% - ${_paginationContainerRef?.current?.clientHeight + 12}px)`}
        borderRadius='20px'
        p='22px'>
        <Flex w='100%' pb='0px' alignItems='end' justifyContent='space-between'>
          <Flex
            w='100%'
            flexDir={{ 'base': 'column', 'md': 'row' }}
            justifyContent='space-between'
            alignItems='center'
            gap='22px'
            zIndex={10}>
            <Flex flex={0.7} flexDir='row' gap='6px' align='center'>
              <Flex color='purple.600'><HiBarsArrowDown size={26} /></Flex>
              <Text fontSize='2xl' fontWeight={300} color='brand.600'>Vendor <Text as='span' color='brand.400'>Smpp</Text> <Text as='span' color='brand.500'>Logs</Text></Text>
            </Flex>
            <Flex
              flex={0.5}
              flexDir='row'
              gap='12px'
              justifyContent='flex-end'
              align='center'>
              <Flex mt='0' width='100%' flex={1}>
                <VendorAccountSelector
                  placeholder='Select Vendor Account'
                  onChange={j => setSmppId(j.target.value?.split?.('(')?.[1]?.split?.(')')?.[0])}
                  isRequired={false}
                />
              </Flex>
              <Flex width='100%' flex={1}>
                <MemoizedSelect
                  placeholder='Select Log Level'
                  onChange={j => setDebuggingLevel([j.target.value])}
                  options={[...Object.React.App.enums.SMPP_DEBUGGING_LEVEL.enums.map(i => i.key), 'BIND', 'UNBIND']}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          bg='gray.50'
          outline='1px solid #C5CFE8'>
          <Table variant='simple' display='block' size='md'>
            <Thead ref={_tableHeaderHeightRef}>
              <Tr
                style={{
                  ...headerStyle,
                  'position': 'sticky',
                  'top': 0,
                  'zIndex': 1
                }}>
                <Td
                  py='10px'
                  textAlign='center'
                  px='0px'
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>S.No</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Flex align='center' gap={1}>
                    <Text>Severity</Text>
                  </Flex>
                </Td>
                <Td
                  py='10px'
                  textAlign='center'
                  px='0px'
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>CreatedAt</Text>
                </Td>
                <Td
                  w='100%'
                  style={rowStyle}>
                  <Flex align='center' gap={1}>
                    <Text>Output</Text>
                  </Flex>
                </Td>
              </Tr>
            </Thead>
            {_QueryLogsRead.loading ? (
              <TableSpinner
                isLoading={true}
                chopHeightFromHundredPercentage={
                  _tableHeaderHeightRef?.current?.clientHeight
                }
              />
            ) : 0 === _queryLogsRead?.length || !_.every(
              _.pluck(_queryLogsRead, 'status'),
              i => 'READ_SUCCESSFUL' === i
            ) ? (
              <TableSpinner
                isLoading={false}
                isEmpty={true}
                chopHeightFromHundredPercentage={
                  _tableHeaderHeightRef?.current?.clientHeight
                }
              />
            ) : (
              <Tbody style={cellStyle}>
                {_queryLogsRead?.map(
                  ({ createdAt, severity, output }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${__index + (skipDifference * skipPage) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        bg={'INFO' === severity ? 'green.50' : 'ERROR' === severity || 'BIND' === severity || 'UNBIND' === severity ? 'red.50' : 'WARNING' === severity ? 'yellow.50' : 'gray.50'}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {severity}{' '}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}{' '}
                      </Td>
                      <Td
                        w='100%'
                        style={rowStyle}>
                        {' '}
                        {JSON.stringify(output ?? {})}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryLogsRead.data?.LogsRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
