/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * OBJECTS
 */
const Index = ({ mccValue, mncValue, isMccAndMncGlobal, inValidMnc, disabled, inValidMcc, isRequired, onChange }) => {
  // Hook assignment.
  const [mcc, setMcc] = React.useState('')
  const [mnc, setMnc] = React.useState([])
  const _QueryMccRead = useQuery(MccReadQuery, { 'variables': { 'take': 5000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if mcc value
     * Is passed in params.
     */
    mccValue && _.isEmpty(mcc) && setMcc(mccValue)
    mncValue && _.isEmpty(mnc) && setMnc(mncValue)
  }, [mccValue, mncValue])
  React.useEffect(() => {
    /*
     * Loop over the _QueryMccRead and set
     * Mcc to Universal.
     */
    if (isMccAndMncGlobal) {
      // Set mcc to universal.
      _QueryMccRead?.data?.MccRead?.map?.(j => {
        // Update mcc and mnc.
        if (0 === parseInt(j.mcc, 10)) {
          // Update mcc and mnc.
          setMcc(`${j.countryName} (${j.mcc})`)
          setMnc(_.compact(j.Mnc.map(e => e.mnc && e.network ? `(${e.mnc}) ${e.network}` : void 0)))

          // Call the onChange function.
          onChange?.({ 'mnc': j.Mnc.map(e => `(${e.mnc}) ${e.network}`), 'mcc': `${j.countryName} (${j.mcc})` })
        }

        // Return data.
        return j
      })
    }
  }, [isMccAndMncGlobal])

  // Const assignment.
  const _selectedTarget = (mcc)?.split('(')[1]?.split(')')[0]
  const _selectedMcc = _QueryMccRead?.data?.MccRead?.filter?.(j => j.mcc.includes(_selectedTarget))
  const _mcc = _selectedMcc?.[0]

  // Return the JSX.
  return (
    <Flex w='100%' className='mccAndMncSelector' flexDir='column' gap='22px' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
      <MemoizedSelect
        label='Country Mcc'
        disabled={_QueryMccRead?.loading || disabled}
        name='mcc'
        value={mcc}
        height='100%'
        placeholder='Select Country'
        options={_.compact(_QueryMccRead?.data?.MccRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.countryName} (${j.mcc})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidMcc}
        isRequired={isRequired}
        onChange={__event => {
          // Update states.
          setMcc(__event.target.value)
          setMnc([])

          // Return updated mcc and mnc.
          onChange({ 'mnc': [], 'mcc': __event.target.value })
        }}
      />
      <FormControl w='100%' flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Operator Mnc{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} disabled={_QueryMccRead?.loading || 0 === _mcc?.Mnc?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_QueryMccRead?.loading || disabled || 0 === _mcc?.Mnc?.length}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            bg={inValidMnc ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            rightIcon={<BsCheckAll />}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidMnc ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(mnc) && !_.isEmpty(mnc) ? [mnc] : mnc)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_mcc?.Mnc?.map?.(item => (
              <MenuItem key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedMcc.length}
                  name='mnc'
                  onChange={() => {
                    // Update state of mnc.
                    setMnc(j => {
                      // Variable assignment.
                      j = _.isString(j) ? [j] : j

                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`(${item.mnc}) ${item?.network}`)) {
                        // Const assignment.
                        const _data = _.without(j, `(${item.mnc}) ${item?.network}`)

                        // Return updated mcc and mnc.
                        onChange({ 'mnc': _data, mcc })

                        // Return updated mnc.
                        return _data
                      }

                      // Return updated mnc.
                      onChange({ 'mnc': [...j, `(${item.mnc}) ${item?.network}`], mcc })

                      // Return data.
                      return [...j, `(${item.mnc}) ${item?.network}`]
                    })
                  }}
                  isChecked={mnc.includes(`(${item.mnc}) ${item?.network}`)}>
                  {`(${item.mnc})`} {item?.network}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={_.isEmpty(_mcc) || _QueryMccRead?.loading || disabled || 0 === _mcc?.Mnc?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _mnc = _.map(0 < _mcc?.Mnc?.length ? _mcc.Mnc : [], j => `(${j.mnc}) ${j?.network}`)

                // Update mnc.
                setMnc(_mnc)

                // Return updated mcc and mnc.
                onChange({ 'mnc': _mnc, 'mcc': mcc })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {mnc?.length === _mcc?.mnc?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              disabled={_.isEmpty(_mcc) || _QueryMccRead?.loading || disabled || 0 === _mcc?.Mnc?.length}
              onClick={() => {
                // Const assignment.
                setMnc([])

                // Return updated mcc and mnc.
                onChange({ 'mnc': [], 'mcc': '' })
              }}
              bg='none'
              p='0'>
              {0 === mnc?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'mccValue': PropTypes.string,
  'mncValue': PropTypes.array,
  'inValidMnc': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidMcc': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool,
  'isMccAndMncGlobal': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
