/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import VendorReportDropDownOptions from 'components/VendorReportDropDownOptions'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'


/*
 * STYLES
 */
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const _QueryVendorRead = useQuery(VendorReadQuery, {
    'variables': {
      'skip': skipPage * skipDifference,
      'take': skipDifference,
      'smsStartDate': dateRange?.selection.startDate,
      'smsEndDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Const assignment
  const _data = _.compact(_QueryVendorRead?.data?.VendorRead?.filter(item => item?._smsCountForGivenVendor?.[item?.id] && (0 < item?._smsCountForGivenVendor?.[item?.id]?.smsCount || 0 < item?._smsCountForGivenVendor?.[item?.id]?.smsDelivered) || 0 < item?._smsCountForGivenVendor?.[item?.id]?.smsFailed || 0 < item?._smsCountForGivenVendor?.[item?.id]?.smsAccepted || 0 < item?._smsCountForGivenVendor?.[item?.id]?.smsRejected || 0 < item?._smsCountForGivenVendor?.[item?.id]?.smsUndelivered))

  // Return component.
  return (
    <>
      <Flex className='reportsVendor base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='flex-end' alignItems='center'>
            <Flex flex={0.5} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date.'}</Text>
              <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} w='100%' fontSize='13px' height='max-content' gap='6px' setSelectDate={setDateRange} />
            </Flex>
            <Flex flex={0.5} justifyContent='flex-end'>
              <DownloadToExcel
                cellsData={_data?.map((item, __index) => 'READ_SUCCESSFUL' === item.status ? ({
                  'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                  'Vendor Name': item?.displayName ?? '-',
                  'Total Count': item?._smsCountForGivenVendor?.[item?.id]?.smsCount ?? '-',
                  'Sms Delivered': item?._smsCountForGivenVendor?.[item?.id]?.smsDelivered ?? '-',
                  'Sms Failed': item?._smsCountForGivenVendor?.[item?.id]?.smsFailed ?? '-',
                  'Sms Pending': item?._smsCountForGivenVendor?.[item?.id]?.smsAccepted ?? '-',
                  'Sms Undeliverable': item?._smsCountForGivenVendor?.[item?.id]?.smsUndelivered ?? '-',
                  'Sms Rejected': item?._smsCountForGivenVendor?.[item?.id]?.smsRejected ?? '-'
                }) : void 0)}
                headersData={[
                  'S.No.',
                  'Vendor Name',
                  'Total Count',
                  'Sms Delivered',
                  'Sms Failed',
                  'Sms Pending',
                  'Sms Undeliverable',
                  'Sms Rejected'
                ].map(i => ({ 'key': i, 'label': i }))} />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={headerStyle} position='sticky' top={0} zIndex='docked'>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Vendor Name</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Total Count</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Delivered</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Pending</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Failed</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Undeliverable</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Rejected</Td>
                  <Td style={rowStyle} py='18px'>Actions</Td>
                </Tr>
              </Thead>
              {_QueryVendorRead.loading ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : 0 === _data?.length || (0 < _data?.length && _.every(_.pluck(_data, 'status'), j => 'NO_SMS_FOUND' === j || 'VENDOR_NOT_FOUND' === j)) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_data?.map?.((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsCount ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsDelivered ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsFailed ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsAccepted ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsUndelivered ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?._smsCountForGivenVendor?.[item?.id]?.smsRejected ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}>
                        <VendorReportDropDownOptions
                          vendorId={item?.id}
                          currency={item?.Billing?.currency}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorRead?.data?.VendorRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index

