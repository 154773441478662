/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import VendorSelector from 'components/VendorSelector'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccounttReadUniqueQuery from './__query__/index.vendorAccountt.readUnique.query'
import VendorAccounttUpsertMutation from './__mutation__/index.vendorAccountt.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn, containerStyle }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [vendorAccounttDirectoryId, setVendorAccounttDirectoryId] = React.useState('')
  const [vendorAccountt, setVendorAccountt] = React.useState([])
  const [QueryVendorAccounttReadUnique, QueryVendorAccounttReadUniqueResponse] = useLazyQuery(VendorAccounttReadUniqueQuery, { 'variables': { 'vendorAccounttId': passOn?.vendorAccounttId } })
  const [MutationVendorAccounttUpsert, MutationVendorAccounttUpsertResponse] = useMutation(VendorAccounttUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'vendorAccounttDirectoryId': JoiBrowser.string().required(),
      'vendorAccountt': JoiBrowser.array().required()
    }).options({ 'allowUnknown': true })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ 'displayName': _formDataRef.current?.displayName, vendorAccounttDirectoryId, vendorAccountt })

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Const assignment.
    const _vendorAccounttDirectoryId = isCreateOnly ? passOn.vendorAccounttDirectoryId : vendorAccounttDirectoryId?.split?.('(')?.[1]?.split?.(')')?.[0]
    const _vendorAccountt = _.compact(vendorAccountt?.map?.(r => r && r.includes('(') && r.includes(')') ? r?.split?.('(')?.[1]?.split?.(')')?.[0] : r))

    // Execute account registration mutation.
    const _MutationVendorAccounttUpsert = await MutationVendorAccounttUpsert({
      'variables': {
        'displayName': _formDataRef.current?.displayName,
        'vendorAccountt': _vendorAccountt,
        'vendorAccounttId': isCreateOnly ? void 0 : passOn?.vendorAccounttId,
        'vendorAccounttDirectoryId': isCreateOnly ? _vendorAccounttDirectoryId : void 0
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationVendorAccounttUpsert instanceof Error) return _MutationVendorAccounttUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationVendorAccounttUpsert?.data?.VendorAccounttUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationVendorAccounttUpsert?.data?.VendorAccounttUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorAccounttReadUniqueQuery = await QueryVendorAccounttReadUnique({ 'variables': { 'vendorAccounttId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorAccounttId } })

      // If query caught an exception then report failure.
      if (_QueryVendorAccounttReadUniqueQuery instanceof Error) return _QueryVendorAccounttReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryVendorAccounttReadUniqueQuery?.data?.VendorAccounttReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'displayName': _QueryVendorAccounttReadUniqueQuery?.data?.VendorAccounttReadUnique?.displayName
        }

        // Update state of selectors.
        setVendorAccounttDirectoryId(_.first(_QueryVendorAccounttReadUniqueQuery?.data?.VendorAccounttReadUnique?.VendorAccount?.map(e => e?.Vendor && e?.Vendor?.displayName ? `${e?.Vendor?.displayName} (${e?.Vendor?.id})` : void 0)))
        setVendorAccountt(_QueryVendorAccounttReadUniqueQuery?.data?.VendorAccounttReadUnique?.VendorAccount?.map(e => e && e.displayName ? `${e?.displayName} (${e?.id})` : void 0))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorAccounttUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryVendorAccounttReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={containerStyle} onSubmit={_SubmitForm}>
      <MemoizedInput
        isRequired
        label='Display Name'
        placeholder='e.g. "Special LCR"'
        value={_formDataRef.current?.displayName}
        onChange={e => {
          // Const assignment.
          const { value } = e.target

          // Update form data.
          _formDataRef.current = { 'displayName': value }
        }}
        isInvalid={error?.includes('displayName')}
        disabled={_isInputDisabled}
      />
      <VendorSelector
        disabled={_isInputDisabled}
        vendorValue={vendorAccounttDirectoryId}
        vendorAccountValue={vendorAccountt}
        inValidVendorAccount={error?.includes('vendorAccounttId')}
        inValidVendor={error?.includes('vendorAccounttDirectoryId')}
        onChange={i => {
          // Update form data.
          setVendorAccounttDirectoryId(i.vendorId)
          setVendorAccountt(i.vendorAccountId)
        }}
      />
      <SubmitButton
        onSubmit={_SubmitForm}
        disabled={_isInputDisabled}
        isLoading={_isLoading}
        defaultText='Add Vendor Account'
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'containerStyle': PropTypes.object
}
Index.defaultProps = {
  'containerStyle': {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '22px'
  }
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
