/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useHistory } from 'react-router-dom' // Npm: react-router-dom for routing.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiArrowPathRoundedSquare, HiCodeBracketSquare, HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  routeId,
  type,
  onRouteUpdate,
  onRouteDelete,
  onRouteTerminalOpen,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return Component.
  return (
    <Menu placement='left'>
      <MenuButton
        as={Button}
        margin='auto'
        borderRadius='8px'
        color='gray.500'
        bg='transparent'>
        <HiPencil size={18} />
      </MenuButton>
      <MenuList
        className='dropDownMenu'
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push route id to url.
            PassOnUpdate({ routeId })

            // Execute handler.
            onRouteUpdate()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Edit Route</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push route id to url.
            PassOnUpdate({ routeId })

            // Execute handler.
            onRouteDelete()
          }}
          minH='40px'>
          <HiTrash />
          <span>Delete Route</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push route id to url.
            PassOnUpdate({ routeId, type })

            // Execute handler.
            onRouteTerminalOpen()
          }}
          minH='40px'>
          <HiCodeBracketSquare />
          <span>Connect Terminal</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push route id to url.
            PassOnUpdate({ routeId, type })

            // Execute handler.
            _historyRef.push('/route/plan')
          }}
          minH='40px'>
          <HiArrowPathRoundedSquare />
          <span>Manage Route Plan</span>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'routeId': PropTypes.string,
  'type': PropTypes.string,
  'onRouteUpdate': PropTypes.func,
  'onRouteDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func,
  'onRouteTerminalOpen': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
