/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { HiArrowUpCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccountReadQuery from './__query__/index.account.read.query'
import AccountUpdateMutation from './__mutation__/index.account.update.mutation'
import AccountCreateMutation from './__mutation__/index.account.create.mutation'
import AccountUploadMutation from './__mutation__/index.account.upload.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, account, onClose, passOn }) => {
  // Local variable.
  let _thumbnailStoredAt

  // Hook assignment.
  const [file, setFile] = React.useState(null)
  const [isLoadingInProgress, setIsLoadingInProgress] = React.useState(false)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryAccountRead, QueryAccountReadResponse] = useLazyQuery(AccountReadQuery, { 'variables': { 'accountId': passOn?.accountId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const [MutationAccountUpload] = useMutation(AccountUploadMutation)
  const [MutationAccountCreate, MutationAccountCreateResponse] = useMutation(AccountCreateMutation)
  const [MutationAccountUpdate, MutationAccountUpdateResponse] = useMutation(AccountUpdateMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'accountId': isCreateOnly ? JoiBrowser.string().optional() : JoiBrowser.string().required(),
      'displayName': isCreateOnly ? JoiBrowser.string().required() : JoiBrowser.string().optional(),
      'email': JoiBrowser.string().email({ 'tlds': { 'allow': false } }).required(),
      'password': isCreateOnly ? JoiBrowser.string().required() : JoiBrowser.string().optional(),
      'fullName': JoiBrowser.string().optional(),
      'bio': JoiBrowser.string().optional(),
      'website': JoiBrowser.string().optional(),
      'isBlocked': JoiBrowser.boolean().optional()
    }).options({ 'allowUnknown': true })


    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)
console.log(_formDataRef.current)
    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with accountId
     * if it is not available on _formDataRef.
     * also make sure that mode is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.accountId)) _formDataRef.current = { ..._formDataRef?.current, 'accountId': passOn?.accountId }
console.log(_.omit(_formDataRef.current))
    // Execute update mutation.
    const _MutationAccountUpdate = await [isCreateOnly ? MutationAccountCreate : MutationAccountUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'accountId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationAccountUpdate instanceof Error) return _MutationAccountUpdate

    /*
     * If user has uploaded the profile picture then update it.
     * as well.
     */
    if (file) {
      // Update loading state.
      setIsLoadingInProgress(true)

      // Execute mutation.
      const _MutationAccountUpload = await MutationAccountUpload({ 'variables': { 'thumbnailStoredAt': file, 'accountId': account.id } })

      // Update loading state.
      setIsLoadingInProgress(false)

      // Toast message on _MutationAccountUpload.
      toast(_MutationAccountUpload?.data?.AccountUpload?.message)
    }

    // Style Guide.
    toast(_MutationAccountUpdate?.data?.AccountSetting?.message ?? _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)

    /*
     * Update error if mutation execution contains
     * status.
     */
    if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return setError(_MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)
    if ('ACCOUNT_WITH_EMAIL_FOUND' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return setError(_MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)
    if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountUpdate?.data?.AccountSetting?.status) return setError(_MutationAccountUpdate?.data?.AccountSetting?.message)
    if ('UPDATE_SUCCESSFUL' === _MutationAccountUpdate?.data?.AccountSetting?.status) return onClose?.()
    if ('CREATE_SUCCESSFUL' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return onClose?.()

    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryAccountReadQuery = await QueryAccountRead({ 'variables': { 'accountId': isCreateOnly ? 'UN_KNOWN' : passOn?.accountId } })

      // If query caught an exception then report failure.
      if (_QueryAccountReadQuery instanceof Error) return _QueryAccountReadQuery

      /*
       * If account details fetch complete then
       * update its value.
       */
      if (_.first(_QueryAccountReadQuery?.data?.AccountRead)) {
        // Update form data.
        _formDataRef.current = {
          'accountId': passOn?.accountId,
          'displayName': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.displayName,
          'fullName': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.fullName,
          'bio': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.bio,
          'email': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.email,
          'website': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.website,
          'isBlocked': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.isBlocked,
          'thumbnailStoredAt': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.thumbnailStoredAt
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationAccountCreateResponse.loading || MutationAccountUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryAccountReadResponse?.loading

  /*
   * If view is currently opened for update.
   * then update profile picture of the account.
   */
  if (_formDataRef.current?.thumbnailStoredAt) {
    // Update profile picture.
    _thumbnailStoredAt = _formDataRef.current?.thumbnailStoredAt?.path
  } else if (file) {
    // Update profile picture.
    _thumbnailStoredAt = URL.createObjectURL(file)
  }

  // Return component.
  return (
    <Flex direction='column' key={forceReRender}>
      {
        isCreateOnly ? (
          <form onSubmit={_SubmitForm}>
            <Flex gap='22px' flexDir='column' w='100%'>
              <Flex position='relative' align='center'>
                {file ? <img style={{ 'width': '45px', 'height': '45px', 'borderRadius': 100 }} src={URL.createObjectURL(file)} alt='logo' /> : (<Flex w='45px' h='45px' borderRadius='100%' bg='gray.100' />)}
                <FormControl opacity='0.5' w='max-content' borderRadius={8} borderWidth={0} overflow='hidden' position='absolute'>
                  <FormLabel mt='10px' htmlFor='file' fontWeight='400' fontSize='md' px={4} cursor='pointer' _hover={{ 'bg': 'transparent' }} display='flex' flexDir='row' gap='12px' alignItems='center'>{isLoadingInProgress ? (<Spinner size='sm' color='purple.500' />) : (<HiArrowUpCircle />)}</FormLabel>
                  <Box position='absolute' top='0' left='0' width='100%' height='100%' opacity={0}>
                    <Input
                      type='file'
                      border='none'
                      w={{ 'base': '100%', 'md': 'auto' }}
                      sx={{
                        '::file-selector-button': {
                          'height': 10,
                          'padding': 0,
                          'mr': 4,
                          'fontWeight': '400',
                          'background': 'none',
                          'border': 'none',
                          'color': 'purple'
                        }
                      }}
                      _focus={{
                        'border': 'none'
                      }}
                      _hover={{
                        'border': 'none'
                      }}
                      onChange={e => Promise.all(Array.from(e.target.files).map(__e => setFile(__e)))}
                    />
                  </Box>
                </FormControl>
              </Flex>
              <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='displayName'
                  label='Display Name'
                  isRequired={true}
                  placeholder='Enter Display Name'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isInvalid={error?.includes('displayName')}
                  error={error}
                  data={_formDataRef?.current?.displayName}
                />
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='email'
                  label='Email'
                  placeholder='Enter account email'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isRequired={true}
                  error={error}
                  isInvalid={error?.includes('email')}
                  data={_formDataRef?.current?.email}
                />
              </Flex>
              <Flex w='100%'>
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='password'
                  label='Account Password'
                  placeholder='Enter account password'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isRequired={true}
                  error={error}
                  isInvalid={error?.includes('password')}
                  data={_formDataRef?.current?.password}
                />
              </Flex>
            </Flex>
            <SubmitButton
              disabled={_isInputDisabled}
              onSubmit={_SubmitForm}
              defaultText={isCreateOnly ? 'Create Account' : 'Update Account'}
              isLoading={MutationAccountCreateResponse.loading} />
          </form>
        ) : (
          <form onSubmit={_SubmitForm}>
            <Flex gap='22px' flexDir='column' w='100%'>
              <Flex position='relative' align='center'>
                {_thumbnailStoredAt ? <img style={{ 'width': '45px', 'height': '45px', 'borderRadius': 100 }} src={_thumbnailStoredAt} alt='logo' /> : (<Flex w='45px' h='45px' borderRadius='100%' bg='gray.100' />)}
                <FormControl opacity='0.5' w='max-content' borderRadius={8} borderWidth={0} overflow='hidden' position='absolute'>
                  <FormLabel mt='10px' htmlFor='file' fontWeight='400' fontSize='md' px={4} cursor='pointer' _hover={{ 'bg': 'transparent' }} display='flex' flexDir='row' gap='12px' alignItems='center'>{isLoadingInProgress ? (<Spinner size='sm' color='purple.500' />) : (<HiArrowUpCircle />)}</FormLabel>
                  <Box position='absolute' top='0' left='0' width='100%' height='100%' opacity={0}>
                    <Input
                      type='file'
                      border='none'
                      w={{ 'base': '100%', 'md': 'auto' }}
                      sx={{
                        '::file-selector-button': {
                          'height': 10,
                          'padding': 0,
                          'mr': 4,
                          'fontWeight': '400',
                          'background': 'none',
                          'border': 'none',
                          'color': 'purple'
                        }
                      }}
                      _focus={{
                        'border': 'none'
                      }}
                      _hover={{
                        'border': 'none'
                      }}
                      onChange={e => Promise.all(Array.from(e.target.files).map(__e => setFile(__e)))}
                    />
                  </Box>
                </FormControl>
              </Flex>
              <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='displayName'
                  label='Display Name'
                  placeholder='Enter Display Name'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isInvalid={error?.includes('displayName')}
                  error={error}
                  data={_formDataRef?.current?.displayName}
                />
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='fullName'
                  label='Full Name'
                  placeholder='Enter Full Name'
                  error={error}
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isInvalid={error?.includes('fullName')}
                  data={_formDataRef?.current?.fullName}
                />
              </Flex>
              <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='bio'
                  label='Account Bio'
                  placeholder='Enter account bio'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  error={error}
                  isInvalid={error?.includes('bio')}
                  data={_formDataRef?.current?.bio}
                />
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='email'
                  label='Account Email'
                  placeholder='Enter account email'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  error={error}
                  isInvalid={error?.includes('email')}
                  data={_formDataRef?.current?.email}
                />
              </Flex>
              <Flex w='100%'>
                <MemoizedInput
                  disabled={_isInputDisabled}
                  name='password'
                  label='Account Password'
                  placeholder='Enter account password'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  isRequired={true}
                  error={error}
                  isInvalid={error?.includes('password')}
                  data={_formDataRef?.current?.password}
                />
              </Flex>
            </Flex>
            <SubmitButton
              disabled={_isInputDisabled}
              onSubmit={_SubmitForm}
              defaultText={isCreateOnly ? 'Create Account' : 'Update Account'}
              isLoading={MutationAccountUpdateResponse.loading} />
          </form>
        )
      }
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'account': PropTypes.object,
  'platform': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account, 'platform': __state.Platform, 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
