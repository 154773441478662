/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerType: CUSTOMER_TYPE, $search: String, $take: PositiveInt, $skip: Int) {
  CustomerRead(customerType: $customerType, search: $search, take: $take, skip: $skip) {
    id,
    createdAt,
    displayName,
    message,
    overDraftLimit,
    phone,
    status,
    country,
    type,
    _totalCount
    AccountManager {
      displayName
    }
    CustomerAccount {
      Smpp {
        sourceAddress
      }
      Route {
          displayName,
          RoutePlan {
            Sms {
              id,
              status
            }
          }
        }
    }
    Credit {
      updatedAt,
      limit,
      balance,
      alertAmount,
      creditHistory
    }
    Company {
      displayName,
    }
  }
}
`
