/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query PhoneNumberoDirectoryReadQuery($take: PositiveInt!, $skip: Int!) {
    PhoneNumberoDirectoryRead(take: $take, skip: $skip) {
      id,
      displayName,
      message,
      status,
      PhoneNumbero {
        id,
        phoneNumbero
      }
    }
  }
`
