/*
 * IMPORTS
 */
import 'mapbox-gl/dist/mapbox-gl.css' // Npm: Mapbox css.
import React from 'react' // Npm: react.js library.
import Geo from 'pangnote-cities' // Npm: Pangnote cities.
import process from 'process' // Node: process library.
import _ from 'underscore' // Npm: underscore.js library.
import Map, { Marker } from 'react-map-gl' // Npm: React mapbox.
import { TbCircleDotFilled } from 'react-icons/tb' // Npm: React icons.
import { useLazyQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip,
  useBreakpointValue,
  useStyleConfig,
  WrapItem
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'


/*
 * GRAPHS
 */
import SmsGroupByQuery from './__query__/index.sms.read.query'


/*
 * OBJECTS
 */
export default function YourTransfers() {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [markers, setMarkers] = React.useState([])
  const [QuerySmsGroupBy] = useLazyQuery(SmsGroupByQuery)
  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      /*
       * Fetch mcc with sms sent through
       * the platform.
       */
      const _QuerySmsGroupBy = await QuerySmsGroupBy({ 'variables': { 'groupBy': ['persisted__countryName'], 'startDate': dateRange?.selection.startDate, 'endDate': dateRange?.selection.endDate, 'take': 1000000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

      console.log('-===e=wr', _QuerySmsGroupBy)
      // If getting data caught exception then report failure.
      if (_QuerySmsGroupBy instanceof Error) throw _QuerySmsGroupBy

      // If data is found then update markers.
      if (_QuerySmsGroupBy?.data?.SmsGroupBy) {
        // Const assignment.
        const _markers = _.compact(Object.entries(_QuerySmsGroupBy?.data?.SmsGroupBy ?? {})?.map?.(j => _.compact([Geo.getCountryGeo(j?.[1]?.persisted__countryname), j?.[1]?._totalCount, j?.[1]?.persisted__countryname])))

               // Update markers.
      !_.isEmpty(_markers) && _markers?.length > 0 && setMarkers(_markers?.filter(j => !_.isEmpty(j)))
      }
         }; _Async().catch(error => { throw error })
  }, [dateRange])

  console.log('-x-x-x-', markers)
  // Return component.
  return (
    <Box
      __css={_styles}
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      h='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justifyContent='space-between' alignItems='center' w='100%' mb={_isCurrentViewMobile ? '12px' : '22px'}>
        <Text
          color='rgba(43,54,116,1)'
          fontWeight='700'
          fontSize={{ 'base': 'sm', 'md': 'md' }}
          textAlign='left'>
          COUNTRY STATS
        </Text>
        <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} w='100%' fontSize='13px' height='max-content' setSelectDate={setDateRange} />
      </Flex>
      <Flex justify='space-between' h='inherit'>
        <Flex h='100%' w='100%' position='relative'>
          <Map
            initialViewState={{
              'latitude': 37.692,
              'longitude': -122.435,
              'zoom': 0
            }}
            style={{
              'borderRadius': '18px',
              'width': '100%',
              'minHeight': '300px',
              'maxHeight': '70vh',
              'height': '100%'
            }}
            mapStyle='mapbox://styles/mapbox/outdoors-v12'
            mapboxAccessToken={process.env.REACT_APP_MAP_KEY}>
            {
              markers.map((p, i) => (
                <Marker
                  key={i}
                  latitude={p?.[0]?.[0] ?? 23}
                  longitude={p?.[0]?.[1] ?? -102}>
                  <WrapItem>
                    <Tooltip label={`${p?.[2]} : ${p[1] ?? 0}`} shouldWrapChildren>
                      <TbCircleDotFilled
                        color={2000 > p[1] ? 'tomato' : 2000 <= p[1] && 10000 >= p[1] ? 'gray' : 'black'}
                        w='15px'
                        h='15px'
                      />
                    </Tooltip>
                  </WrapItem>
                </Marker>
              ))
            }
          </Map>
          <Flex
            w='170px'
            flexDir='column'
            h='123px'
            borderRadius='15px'
            bg='white'
            bottom='5px'
            left='5px'
            zIndex='2'
            p='12px'
            position='absolute'
            alignItems='start'
            justifyContent='space-between'
            color='#707EAE'>
            <Text fontSize='15px' fontWeight='700'>
              Global SMS Volume
            </Text>
            <List textAlign='left'>
              <ListItem>
                <ListIcon as={TbCircleDotFilled} color='tomato' />
                {`<${2000}`}
              </ListItem>
              <ListItem>
                <ListIcon as={TbCircleDotFilled} color='gray' />
                {`>=${2000}`}
              </ListItem>
              <ListItem>
                <ListIcon as={TbCircleDotFilled} color='black' />
                {`>${10000}`}
              </ListItem>
            </List>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
