/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccounttDirectoryReadQuery from './__query__/index.vendorAccounttDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ bg, color, vendorAccounttDirectoryValue, vendorAccounttValue, inValidVendorAccount, disabled, inValidVendorAccounttDirectory, isRequired, onChange }) => {
  // Hook assignment.
  const [vendorAccounttDirectory, setVendorAccounttDirectory] = React.useState(vendorAccounttDirectoryValue)
  const [vendorAccountt, setVendorAccountt] = React.useState([])
  const _QueryVendorAccounttDirectoryRead = useQuery(VendorAccounttDirectoryReadQuery, { 'variables': { 'take': 5000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if vendorAccounttDirectory value
     * Is passed in params.
     */
    vendorAccounttDirectoryValue && _.isEmpty(vendorAccounttDirectory) && setVendorAccounttDirectory(vendorAccounttDirectoryValue ?? '')
    vendorAccounttValue && _.isEmpty(vendorAccountt) && setVendorAccountt(vendorAccounttValue ?? [])
  }, [vendorAccounttDirectoryValue, vendorAccounttValue])

  // Const assignment.
  const _selectedTarget = (vendorAccounttDirectory)?.split?.('(')[1]?.split?.(')')?.[0]
  const _selectedVendorAccounttDirectory = _QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedVendorAccounttDirectory = _selectedVendorAccounttDirectory?.[0]?.VendorAccountt

  // Return the JSX.
  return (
    <Flex w='100%' className='vendorAccounttDirectoryAndVendorAccountSelector' flexDir='column' gap='15px'>
      <MemoizedSelect
        label='LCR V2 Folder'
        disabled={_QueryVendorAccounttDirectoryRead?.loading || disabled}
        name='vendorAccounttDirectory'
        value={vendorAccounttDirectory}
        placeholder='Select Folder'
        bg={bg}
        color={color}
        options={_.compact(_QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidVendorAccounttDirectory}
        isRequired={isRequired}
        onChange={__event => {
          // Update states.
          setVendorAccounttDirectory(__event.target.value)
          setVendorAccountt([])

          // Return updated vendorAccounttDirectory and vendorAccountt.
          onChange({
            'vendorAccountt': [],
            'vendorAccounttDirectory': __event.target.value
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Vendor Account&apos;s{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedVendorAccounttDirectory) || _QueryVendorAccounttDirectoryRead?.loading || 0 === _firstSelectedVendorAccounttDirectory?.VendorAccount?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_firstSelectedVendorAccounttDirectory) || _QueryVendorAccounttDirectoryRead?.loading || disabled || 0 === _firstSelectedVendorAccounttDirectory?.VendorAccount?.length}
            h='40px'
            color={color}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidVendorAccount ? 'rgb(255,255,255,0.8)' : bg ?? 'gray.100'}
            _hover={{ 'bg': bg ?? 'gray.100' }}
            _expanded={{ 'bg': bg ?? 'gray.100' }}
            _focus={{ 'bg': bg ?? 'gray.100' }}
            boxShadow={inValidVendorAccount ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(vendorAccountt) && !_.isEmpty(vendorAccountt) ? [vendorAccountt] : vendorAccountt)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead?.map?.(item => item?.id?.includes(_selectedTarget) ? item?.VendorAccountt?.map?.(r => _.isEmpty(r?.VendorAccount) ? void 0 : (
              <MenuItem closeOnSelect={false} key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedVendorAccounttDirectory.length}
                  name='vendorAccountt'
                  onChange={() => {
                    // Update state of vendorAccounts.
                    setVendorAccountt(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r?.displayName} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r?.displayName} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'vendorAccountt': _data, 'vendorAccounttDirectory': vendorAccounttDirectory })

                        // Update state of vendorAccounts.
                        return _data
                      }

                      // Return updated vendorAccountt.
                      onChange({ 'vendorAccountt': [...j, `${r?.displayName} (${r?.id})`], 'vendorAccounttDirectory': vendorAccounttDirectory })

                      // Update state of vendorAccounts.
                      return [...j, `${r?.displayName} (${r?.id})`]
                    })
                  }}
                  isChecked={vendorAccountt.includes(`${r?.displayName} (${r?.id})`)}>
                  {`${r?.displayName} (${r?.id})`}
                </Checkbox>
              </MenuItem>
            )) : void 0)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={_.isEmpty(_firstSelectedVendorAccounttDirectory) || _QueryVendorAccounttDirectoryRead?.loading || disabled || 0 === _firstSelectedVendorAccounttDirectory?.VendorAccount?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _vendorAccountIds = []

                // Loop over the directory and get all child.
                for (const item of _QueryVendorAccounttDirectoryRead.data.VendorAccounttDirectoryRead) {
                  // Loop over VendorAccountt.
                  for (const _vendorAccount of item.VendorAccountt) {
                    // Only proceed if given item is selected one.
                    if (item?.id?.includes?.(_selectedTarget) && !_.isEmpty(_vendorAccount.VendorAccount)) {
                      // Push vendor id to container.
                      _vendorAccountIds.push(`${_vendorAccount.displayName} (${_vendorAccount.id})`)
                    }
                  }
                }

                // Update vendorAccounts.
                setVendorAccountt(_vendorAccountIds)

                // Return updated vendorAccounts.
                onChange({ 'vendorAccountt': _vendorAccountIds, 'vendorAccounttDirectory': vendorAccounttDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {vendorAccountt?.length === _firstSelectedVendorAccounttDirectory?.vendorAccountt?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={_.isEmpty(_firstSelectedVendorAccounttDirectory) || _QueryVendorAccounttDirectoryRead?.loading || disabled || 0 === _firstSelectedVendorAccounttDirectory?.VendorAccount?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update vendorAccounts.
                setVendorAccountt([])
                setVendorAccounttDirectory('')

                // Return updated state.
                onChange({ 'vendorAccountt': [], 'vendorAccounttDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === vendorAccountt?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'vendorAccounttDirectoryValue': PropTypes.string,
  'vendorAccounttValue': PropTypes.array,
  'inValidVendorAccount': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidVendorAccounttDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool,
  'bg': PropTypes.string,
  'color': PropTypes.string
}


/*
 * EXPORT
 */
export default Index
