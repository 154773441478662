/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import BlockIpDeleteMany from './__mutation__/index.blockIp.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationBlockIpDelete, MutationBlockIpDeleteResponse] = useMutation(BlockIpDeleteMany)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} onConfirm={() => MutationBlockIpDelete({ 'variables': { 'blockIpIds': passOn?.blockIpIds ? passOn?.blockIpIds : passOn?.blockIpId } }).then(i => {
      // Style Guide.
      i?.data?.BlockIpDeleteMany?.map(ir => toast(ir?.message))

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationBlockIpDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
