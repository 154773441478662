/*
 * EXPORTS
 */
export const rowStyle = {
  'fontWeight': '500',
  'fontSize': 'Clamp(13px, 1.5vw, 15px)',
  'textAlign': 'center',
  'minWidth': '120px',
  'maxWidth': '120px'
}
export const headerStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '600',
  'fontSize': '17px',
  'height': '42px',
  'color': '#8D97B5',
  'backgroundColor': '#F4F7FD',
  'textAlign': 'center',
  'minWidth': '120px',
  'maxWidth': '120px'
}
export const cellStyle = {
  'fontFamily': 'Dm Sans',
  'color': 'rgba(43, 54, 116, 1)',
  'fontWeight': '700',
  'fontSize': '18px',
  'lineHeight': '24px',
  'letterSpacing': '-0.36px'
}
