/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SenderIdDirectoryReadQuery from './__query__/index.senderIdDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ senderIdDirectoryValue, senderIdValue, inValidSenderId, disabled, inValidSenderIdDirectory, isRequired, onChange }) => {
  // Hook assignment.
  const [senderIdDirectory, setSenderIdDirectory] = React.useState('')
  const [senderId, setSenderId] = React.useState([])
  const _QuerySenderIdDirectoryRead = useQuery(SenderIdDirectoryReadQuery, { 'variables': { 'take': 5000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if senderIdDirectory value
     * Is passed in params.
     */
    senderIdDirectoryValue && _.isEmpty(senderIdDirectory) && setSenderIdDirectory(senderIdDirectoryValue ?? '')
    senderIdValue && _.isEmpty(senderId) && setSenderId(senderIdValue ?? [])
  }, [senderIdDirectoryValue, senderIdValue])

  // Const assignment.
  const _selectedTarget = (senderIdDirectory)?.split?.('(')[1]?.split?.(')')?.[0]
  const _selectedSenderIdDirectory = _QuerySenderIdDirectoryRead?.data?.SenderIdDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedSenderIdDirectory = _selectedSenderIdDirectory?.[0]

  // Return the JSX.
  return (
    <Flex w='100%' className='senderIdDirectoryAndSenderIdSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='Sender Id&apos;s Folder'
        disabled={_QuerySenderIdDirectoryRead?.loading || disabled}
        name='senderIdDirectory'
        value={senderIdDirectory}
        placeholder='Select Folder'
        options={_.compact(_QuerySenderIdDirectoryRead?.data?.SenderIdDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidSenderIdDirectory}
        onChange={__event => {
          // Update states.
          setSenderIdDirectory(__event.target.value)
          setSenderId([])

          // Return updated senderIdDirectory and senderId.
          onChange({
            'senderId': [],
            'senderIdDirectory': __event.target.value
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Sender Id&apos;s{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedSenderIdDirectory) || _QuerySenderIdDirectoryRead?.loading || 0 === _firstSelectedSenderIdDirectory?.SenderId?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_firstSelectedSenderIdDirectory) || _QuerySenderIdDirectoryRead?.loading || disabled || 0 === _firstSelectedSenderIdDirectory?.SenderId?.length}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidSenderId ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidSenderId ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(senderId) && !_.isEmpty(senderId) ? [senderId] : senderId)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_QuerySenderIdDirectoryRead?.data?.SenderIdDirectoryRead?.map?.(item => item?.id?.includes(_selectedTarget) ? item?.SenderId?.map?.(r => _.isEmpty(r?.senderId) ? void 0 : (
              <MenuItem key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedSenderIdDirectory.length}
                  name='senderId'
                  onChange={() => {
                    // Update state of senderIds.
                    setSenderId(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r.senderId} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r.senderId} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'senderId': _data, 'senderIdDirectory': senderIdDirectory })

                        // Update state of senderIds.
                        return _data
                      }

                      // Return updated senderId.
                      onChange({ 'senderId': [...j, `${r.senderId} (${r?.id})`], 'senderIdDirectory': senderIdDirectory })

                      // Update state of senderIds.
                      return [...j, `${r.senderId} (${r?.id})`]
                    })
                  }}
                  isChecked={senderId.includes(`${r.senderId} (${r?.id})`)}>
                  {`${r.senderId} (${r?.id})`}
                </Checkbox>
              </MenuItem>
            )) : void 0)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={_.isEmpty(_firstSelectedSenderIdDirectory) || _QuerySenderIdDirectoryRead?.loading || disabled || 0 === _firstSelectedSenderIdDirectory?.SenderId?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _senderIds = []

                /*
                 * Loop over sender id directory
                 * and get all sender id.
                 */
                for (const item of _QuerySenderIdDirectoryRead.data.SenderIdDirectoryRead) {
                  // Only proceed if given item is selected one.
                  if (item?.id?.includes?.(_selectedTarget)) {
                    // Loop over senderId container.
                    for (const _senderId of item.SenderId) {
                      // Push sender id to container.
                      _senderIds.push(`${_senderId.senderId} (${_senderId.id})`)
                    }
                  }
                }

                // Update senderIds.
                setSenderId(_senderIds)

                // Return updated senderIds.
                onChange({ 'senderId': _senderIds, 'senderIdDirectory': senderIdDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {senderId?.length === _firstSelectedSenderIdDirectory?.senderId?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={_.isEmpty(_firstSelectedSenderIdDirectory) || _QuerySenderIdDirectoryRead?.loading || disabled || 0 === _firstSelectedSenderIdDirectory?.SenderId?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update senderIds.
                setSenderId([])

                // Return updated state.
                onChange({ 'senderId': [], 'senderIdDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === senderId?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'senderIdDirectoryValue': PropTypes.string,
  'senderIdValue': PropTypes.array,
  'inValidSenderId': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidSenderIdDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
