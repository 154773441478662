/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RateReadQuery($search: String, $type: CUSTOMER_TYPE, $take: PositiveInt, $skip: Int) {
  RateRead(search: $search, type: $type, take: $take, skip: $skip) {
    id,
    displayName,
    type,
    message
    status
  }
}
`
