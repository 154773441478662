/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { createIcon } from '@chakra-ui/icons' // Npm: Chakra UI icons.
import { HiArrowUpCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import { useMutation } from '@apollo/client' // Npm: Apollo client for handling graphql request.
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * GLOBALS
 */
const Logo = createIcon({
  'displayName': 'Logo',
  'viewBox': '0 0 55px 55px',
  'path': (
    <>
      <circle cx='77.2852' cy='25.3189' r='21.0299' fill='url(#paint0_linear_1306_1279)' />
      <path d='M75.6348 50.3874L78.3563 31.9141L82.1499 34.5531L75.6348 50.3874Z' fill='#FFD338' />
      <path d='M89.4893 0H74.6447L64.3359 25.1534L78.9331 19.9578L89.4893 0Z' fill='#FFD338' />
      <path d='M76.6241 31.7528L72.8305 29.0312L72.6655 34.1444L76.6241 31.7528Z' fill='#A7C6DA' stroke='#A7C6DA' strokeWidth='0.16494' />
      <path d='M83.5034 34.4385L87.4153 17.4868C87.5627 16.848 86.9418 16.3009 86.3266 16.5275L64.4374 24.592C63.8538 24.8069 63.8646 25.636 64.4535 25.8358L71.9241 28.3705L81.4477 35.2133C82.213 35.7632 83.2915 35.3567 83.5034 34.4385Z' fill='white' />
      <path d='M83.0565 20.207L69.7788 27.6293L72.2969 34.2949C72.3604 34.4629 72.6086 34.4228 72.616 34.2434L72.8302 29.0313L83.0565 20.207Z' fill='#D9D9D9' />
      <defs>
        <linearGradient id='paint0_linear_1306_1279' x1='93.2844' y1='10.6393' x2='49.5753' y2='49.0703' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#9E64DD' />
          <stop offset='0.387508' stopColor='#8D90FF' />
          <stop offset='0.806162' stopColor='#F357B5' />
        </linearGradient>
      </defs>
    </>
  )
})


/*
 * GRAPHS
 */
import AccountUploadMutation from './__mutation__/index.account.upload.mutation'


/*
 * OBJECTS
 */
const Index = ({ account, platform, AccountUpdate }) => {
  // Local variable.
  let _thumbnailStoredAt

  // Hook assignment.
  const logoColor = useColorModeValue('navy.700', 'white')
  const [isLoadingInProgress, setIsLoadingInProgress] = React.useState(false)
  const [MutationAccountUpload] = useMutation(AccountUploadMutation)

  // Upload platform logo if account thumbnail is not set.
  if (!account.thumbnailStoredAt && platform?.logo) {
    // Update thumbnail.
    _thumbnailStoredAt = platform.logo
  } else if (account.thumbnailStoredAt) {
    // Update thumbnail.
    _thumbnailStoredAt = account.thumbnailStoredAt?.path
  } else {
    // Update thumbnail.
    _thumbnailStoredAt = void 0
  }

  // Return Component.
  return (
    <Flex position='relative' align='center' justifyContent='center'>
      {_thumbnailStoredAt ? <img style={{ 'width': '45px', 'height': '45px', 'borderRadius': 100 }} src={_thumbnailStoredAt} alt='logo' /> : <Logo h='55px' width='' color={logoColor} left='70px' position='relative' />}
      <FormControl opacity='0.5' w='max-content' borderRadius={8} borderWidth={0} overflow='hidden' position='absolute'>
        <FormLabel mt='10px' htmlFor='file' fontWeight='400' fontSize='md' px={4} cursor='pointer' _hover={{ 'bg': 'transparent' }} display='flex' flexDir='row' gap='12px' alignItems='center'>{isLoadingInProgress ? (<Spinner size='sm' color='purple.500' />) : (<HiArrowUpCircle />)}</FormLabel>
        <Box position='absolute' top='0' left='0' width='100%' height='100%' opacity={0}>
          <Input
            type='file'
            border='none'
            w={{ 'base': '100%', 'md': 'auto' }}
            sx={{
              '::file-selector-button': {
                'height': 10,
                'padding': 0,
                'mr': 4,
                'fontWeight': '400',
                'background': 'none',
                'border': 'none',
                'color': 'purple'
              }
            }}
            _focus={{
              'border': 'none'
            }}
            _hover={{
              'border': 'none'
            }}
            onChange={e => Promise.all(Array.from(e.target.files).map(async file => {
              // Update loading state.
              setIsLoadingInProgress(true)

              // Execute mutation.
              const _MutationAccountUpload = await MutationAccountUpload({ 'variables': { 'thumbnailStoredAt': file, 'accountId': account.id } })

              // Update loading state.
              setIsLoadingInProgress(false)

              // Toast message on _MutationAccountUpload.
              toast(_MutationAccountUpload?.data?.AccountUpload?.message)

              // Update account thumbnail.
              AccountUpdate({ 'thumbnailStoredAt': _MutationAccountUpload?.data?.AccountUpload?.thumbnailStoredAt })
            }))}
          />
        </Box>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'platform': PropTypes.object.isRequired,
  'account': PropTypes.object.isRequired,
  'AccountUpdate': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account, 'platform': __state.Platform })
const _MapDispatchToProps = __dispatch => ({ 'AccountUpdate': __account => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': __account }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
