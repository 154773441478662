/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query CustomerInvoiceReadQuery($customerId: ID, $take: PositiveInt, $skip: Int) {
      CustomerInvoiceRead(customerId: $customerId, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        amount,
        dueDate,
        billingStartDate,
        billingEndDate,
        taxPercent,
        detailedInvoice,
        invoiceStoredAt,
        isCleared,
        status,
        message,
        _totalCount,
        Customer {
          Company {
            displayName
          }
        },
        Entity {
          displayName
        }
    }
  }
`
