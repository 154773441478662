/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: Lodash library.
import { Flex, FormControl, Text } from '@chakra-ui/react' // Npm: Chakra UI components.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiDevicePhoneMobile, HiGlobeAlt } from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import OperatorPrefixLocalHlrLookupQuery from './__query__/index.operatorPrefix.localHlrLookup.query'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [destinationAddress, setDestinationAddress] = React.useState('')
  const _QueryOperatorPrefixLocalHlrLookup = useQuery(OperatorPrefixLocalHlrLookupQuery, { 'variables': { destinationAddress }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _onSearchInputChange = React.useCallback(Debounce(e => setDestinationAddress(e.target.value), 800), [])

  // Return component.
  return (
    <>
      <Flex
        my='0'
        w='100%'
        borderRadius='20px'
        bg='white'
        p='15px'
        h='100%'
        overflowY='auto'
        gap='12px'
        flexDir='column'
        className='mccAndMncFinder'
        color='#1B2559'
        fontSize={['clamp(13px, 1.5vw, 16px)']}
        justify='space-between'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
        <Text as='h2' fontSize='xl' color='#393A41'>Mcc & Mnc Finder</Text>
        <Text w='100%' as='p' mt='0' my='12px' color='gray.500' gap='6px' className='heading' fontSize='17px'>Enter <Text as='span' cursor='pointer' fontWeight={700} color='brand.500'><HiDevicePhoneMobile size={21} style={{ 'top': 2.5, 'position': 'relative' }} />Phone Number</Text>&nbsp;&nbsp;to view <Text cursor='pointer' as='span' fontWeight={700} color='purple'><HiGlobeAlt size={22} style={{ 'top': 2.5, 'position': 'relative' }} />Operator Details</Text> of given Phone Number. All Details are shorted with max digit match policy.&nbsp;&nbsp;</Text>
        <FormControl>
          <MemoizedInput label='Search' py='22px' bg='gray.100' _focus={{ 'bg': 'gray.200' }} placeholder='e.g. 8826668515' onChange={_onSearchInputChange} />
        </FormControl>
        <Text as='h3' fontSize='md' color='gray.700' fontWeight={600} mt={2}>Operator Details</Text>
        <Flex as='div' flexDir='column' border='1px solid' borderColor='brand.500' borderRadius={12} overflow='hidden'>
          <Flex as='div' flexDir='row' gap='12px' borderBottom='1px solid' borderColor='brand.400'>
            <Text p='12px' as='h3' fontSize='md' color='white' bg='brand.500' width='100px' textAlign='center'>Mcc</Text>
            <Text p='12px' px='6px' as='p' fontSize='md' color='brand.500'>{_QueryOperatorPrefixLocalHlrLookup?.data?.OperatorPrefixLocalHlrLookup?.Mnc?.Mcc?.mcc}</Text>
          </Flex>
          <Flex as='div' flexDir='row' gap='12px' borderBottom='1px solid' borderColor='brand.400' boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
            <Text p='12px' as='h3' fontSize='md' color='white' bg='brand.500' width='100px' textAlign='center'>Mnc</Text>
            <Text p='12px' px='6px' as='p' fontSize='md' color='brand.500'>{_QueryOperatorPrefixLocalHlrLookup?.data?.OperatorPrefixLocalHlrLookup?.Mnc?.mnc}</Text>
          </Flex>
          <Flex as='div' flexDir='row' gap='12px' borderBottom='1px solid' borderColor='brand.400'>
            <Text p='12px' as='h3' fontSize='md' color='white' bg='brand.500' width='100px' textAlign='center'>Operator</Text>
            <Text p='12px' px='6px' as='p' fontSize='md' color='brand.500'>{_QueryOperatorPrefixLocalHlrLookup?.data?.OperatorPrefixLocalHlrLookup?.Mnc?.network}</Text>
          </Flex>
          <Flex as='div' flexDir='row' gap='12px'>
            <Text p='12px' as='h3' fontSize='md' color='white' bg='brand.500' width='100px' textAlign='center'>CC</Text>
            <Text p='12px' px='6px' as='p' fontSize='md' color='brand.500'>{_QueryOperatorPrefixLocalHlrLookup?.data?.OperatorPrefixLocalHlrLookup?.Mnc?.Mcc?.countryName}</Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index



