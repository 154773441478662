/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query KeywordDirectoryReadQuery($keywordDirectoryToSearch: String!, $take: PositiveInt!, $skip: Int!) {
    KeywordDirectoryRead(keywordDirectoryToSearch: $keywordDirectoryToSearch, take: $take, skip: $skip) {
      message,
      status,
      id,
      displayName,
      Keyword {
        id,
        keyword
      }
    }
  }
`
