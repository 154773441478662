/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client for graphql.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React toastify for notifications.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import OperatorPrefixCreateMutation from './__mutation__/index.operatorPrefix.create.mutation'
import OperatorPrefixUpdateMutation from './__mutation__/index.operatorPrefix.update.mutation'
import OperatorPrefixReadQuery from './__query__/index.operatorPrefix.read.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationOperatorPrefixCreate, MutationOperatorPrefixCreateResponse] = useMutation(OperatorPrefixCreateMutation)
  const [MutationOperatorPrefixUpdate, MutationOperatorPrefixUpdateResponse] = useMutation(OperatorPrefixUpdateMutation)
  const [QueryOperatorPrefixRead, QueryOperatorPrefixReadResponse] = useLazyQuery(OperatorPrefixReadQuery)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'prefix': JoiBrowser.number().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create or Update given operatorPrefix.
    const _MutationOperatorPrefix = await [isCreateOnly ? MutationOperatorPrefixCreate : MutationOperatorPrefixUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'mncId' : 'operatorPrefixId']: isCreateOnly ? passOn?.mncId : passOn?.operatorPrefixId,
        ..._formDataRef.current
      }
    })

    // If creating or updating operatorPrefix caught an exception then report failure.
    if (_MutationOperatorPrefix instanceof Error) return _MutationOperatorPrefix

    // Style Guide.
    toast(_MutationOperatorPrefix?.data?.OperatorPrefixCreate?.message || _MutationOperatorPrefix?.data?.OperatorPrefixUpdate?.message)

    // On Successful update or create close the modal.
    if ('CREATE_SUCCESSFUL' === _MutationOperatorPrefix?.data?.OperatorPrefixCreate?.status || 'UPDATE_SUCCESSFUL' === _MutationOperatorPrefix?.data?.OperatorPrefixUpdate?.status) return onClose()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryOperatorPrefixRead = await QueryOperatorPrefixRead({ 'variables': { 'operatorPrefixId': isCreateOnly ? 'UN_KNOWN' : passOn?.operatorPrefixId } })

      // If query caught an exception then report failure.
      if (_QueryOperatorPrefixRead instanceof Error) return _QueryOperatorPrefixRead

      // Const assignment.
      const _operatorPrefixRead = _.first(_QueryOperatorPrefixRead?.data?.OperatorPrefixRead)

      /*
       * If details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_operatorPrefixRead)) {
        // Update form data.
        _formDataRef.current = {
          'prefix': _operatorPrefixRead?.prefix
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationOperatorPrefixCreateResponse.loading || MutationOperatorPrefixUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryOperatorPrefixReadResponse.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        name='prefix'
        label='OperatorPrefix'
        placeholder='e.g. "OperatorPrefix"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: isNaN(Number(value)) ? value : Number(value)
          }
        }}
        error={error}
        isInvalid={error?.includes('prefix')}
        isRequired={true}
        data={_formDataRef?.current?.prefix}
      />
      <SubmitButton
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create OperatorPrefix' : 'Update OperatorPrefix'}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
