/*
 * IMPORTS
 */
import process from 'process' // Node.js: Process library.
import { setContext } from '@apollo/client/link/context' // Npm: Apollo client index.context.js library.
import { ApolloClient, createHttpLink, from, InMemoryCache, split } from '@apollo/client' // Npm: Apollo client for handling graphql.
import { getMainDefinition } from '@apollo/client/utilities' // Npm: Apollo client utility.
import { onError } from '@apollo/client/link/error' // Npm: Apollo client for handling graphql.
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries' // Npm: Apollo client for handling graphql.
import { sha256 } from 'crypto-hash' // Npm: Crypto hash for hashing graphql queries.
import { createUploadLink } from 'apollo-upload-client' // Npm: Apollo client for handling graphql.
import { AES } from 'crypto-js' // Npm: Crypto-js for hashing graphql queries.


/*
 * OBJECTS
 */
export const ApolloClientProvider = (__account, { ClearEverything }) => {
  // Const assignment.
  const _headers = { 'b-authorization': AES.encrypt(`WTF__${String.random(9)}`?.toString(), '===---#$#$#$SUPERSTART#$#$#$-------+P') }
  const _isProduction = true || 'PRODUCTION' === process.env.NODE_ENV?.toUpperCase()
  const _backendURI = _isProduction ? process.env.REACT_APP_NODE_BACKEND_PRODUCTION : process.env.REACT_APP_NODE_BACKEND_DEVELOPMENT

  // Update headers if arguments are available.
  __account.isUserLoggedIn ? _headers['l-authorization'] = __account.token : void 0
  __account.isUserLoggedIn ? _headers.Authorization = `Basic ${btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`)}` : void 0

  // Const assignment.
  const _HttpLink = createHttpLink({
    'uri': _backendURI,
    'credentials': 'same-origin',
    'headers': _headers
  })
  const _ErrorLink = onError(({ graphQLErrors, networkError }) => {
    // Only execute if mode is in production.
    if (_isProduction) {
      // Check if there is any graphql error.
      if (graphQLErrors) {
        /*
         * Check for errors if any one contain
         * Unexpected keyword than clear everything.
         */
        graphQLErrors.forEach(({ message }) => message?.toLowerCase().includes('unexpected') || message?.toLowerCase()?.includes('NOT_FOUND(VERIFY)') ? ClearEverything() : void 0)
        graphQLErrors.forEach(({ message }) => message?.toLowerCase()?.includes('PersistedQueryNotFound') ? void 0 : void 0)
      } else if (networkError) { ClearEverything() }
    }
  })
  const _AuthLink = setContext((__, { headers }) =>
  // Return the headers to the context so httpLink can read them
  ({
    'headers': {
      ...headers,
      ..._headers
    }
  }))
  const _UploadLink = createUploadLink({ 'uri': _backendURI, 'headers': _headers })

  // Const assignment.
  const _PersistanceLink = createPersistedQueryLink({
    sha256,
    'useGETForHashedQueries': true
  }).concat(_HttpLink)

  // Return client.
  return new ApolloClient({
    'link': _AuthLink.concat(split(
      // Split based on operation type
      ({ query }) => {
        // Const assignment.
        const { kind, operation } = getMainDefinition(query)

        // Return operations.
        return 'OperationDefinition' === kind && 'subscription' === operation
      },
      from([_PersistanceLink]),
      from([_ErrorLink, _UploadLink])
    )),
    'cache': new InMemoryCache()
  })
}
