/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import ReportsCreditDropDownOptions from 'components/ReportsCreditDropDownOptions'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const [customerType, setCustomerType] = React.useState(void 0)
  const _QueryCustomerRead = useQuery(CustomerReadQuery, {
    'variables': {
      customerType,
      'search': searchQuery,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)

  // Return component.
  return (
    <>
      <Flex className='reportsVendor base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap='22px'
          bg='white'
          height='100%'
          borderRadius='20px'
          p='16px'
        >
          <Flex pb='0px' justify='flex-end' alignItems='center'>
            <Flex flex={0.5} justifyContent='flex-end'>
              <DownloadToExcel
                cellsData={_.compact(
                  _QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => 'READ_SUCCESSFUL' === item.status ? {
                    'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                    'Customer Name': item?.Company?.displayName ?? '-',
                    'Customer Type': item?.type ?? '-',
                    'Active Balance': item?.Credit?.balance ?? '-',
                    'Credit Limit': item?.overDraftLimit ?? '-',
                    'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                    'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD')
                  } : void 0)
                )}
                headersData={[
                  'S.No.',
                  'Customer name',
                  'Customer Type',
                  'Active Balance',
                  'Credit Limit',
                  'CreatedAt',
                  'UpdatedAt'
                ].map(i => ({ 'key': i, 'label': i }))}
              />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={{
                    ...headerStyle,
                    'position': 'sticky',
                    'top': 0,
                    'zIndex': 1
                  }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Customer Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Customer Type
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Active Balance
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Credit Limit
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  />
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedSelect
                      placeholder='Select Account Type'
                      className='filter searchInput'
                      onChange={e => setCustomerType(_.isEmpty(e?.target?.value) ? void 0 : e?.target?.value)}
                      options={_.without(Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i?.key), 'TERMINATION')}
                      data={customerType}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  />
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  />
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  />
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  />
                  <Td style={rowStyle} />
                </Tr>
              </Thead>
              {_QueryCustomerRead.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && _.every(_.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'), j => 'NO_SMS_FOUND' === j || 'CUSTOMERS_NOT_FOUND' === j)) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${__index + (skipDifference * skipPage) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Company?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.type ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Credit?.balance?.toFixed?.(5) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.overDraftLimit?.toFixed?.(5) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td style={rowStyle}>
                        {
                          <ReportsCreditDropDownOptions
                            creditId={item?.id}
                            history={item?.Credit?.creditHistory}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCustomerRead?.data?.CustomerRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}

/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
