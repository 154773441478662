/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import PhoneNumberoDirectoryReadQuery from './__query__/index.phoneNumberoDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ phoneNumberoDirectoryValue, phoneNumberoValue, inValidPhoneNumbero, disabled, inValidPhoneNumberoDirectory, isRequired, onChange }) => {
  // Hook assignment.
  const [phoneNumberoDirectory, setPhoneNumberoDirectory] = React.useState(phoneNumberoDirectoryValue)
  const [phoneNumbero, setPhoneNumbero] = React.useState([])
  const _QueryPhoneNumberoDirectoryRead = useQuery(PhoneNumberoDirectoryReadQuery, { 'variables': { 'take': 5000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if phoneNumberoDirectory value
     * Is passed in params.
     */
    phoneNumberoDirectoryValue && _.isEmpty(phoneNumberoDirectory) && setPhoneNumberoDirectory(phoneNumberoDirectoryValue ?? '')
    phoneNumberoValue && _.isEmpty(phoneNumbero) && setPhoneNumbero(phoneNumberoValue ?? [])
  }, [phoneNumberoDirectoryValue, phoneNumberoValue])

  // Const assignment.
  const _selectedTarget = (phoneNumberoDirectory)?.split?.('(')?.[1]?.split?.(')')?.[0]
  const _selectedPhoneNumberoDirectory = _QueryPhoneNumberoDirectoryRead?.data?.PhoneNumberoDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedPhoneNumberoDirectory = _selectedPhoneNumberoDirectory?.[0]

  // Return the JSX.
  return (
    <Flex w='100%' className='phoneNumberoDirectoryAndPhoneNumberoSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='Phone Number&apos;s Folder'
        disabled={_QueryPhoneNumberoDirectoryRead?.loading || disabled}
        name='phoneNumberoDirectory'
        value={phoneNumberoDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryPhoneNumberoDirectoryRead?.data?.PhoneNumberoDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidPhoneNumberoDirectory}
        onChange={__event => {
          // Update states.
          setPhoneNumberoDirectory(__event.target.value)
          setPhoneNumbero([])

          // Return updated phoneNumberoDirectory and phoneNumbero.
          onChange({
            'phoneNumbero': [],
            'phoneNumberoDirectory': __event.target.value
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Phone Number&apos;s{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedPhoneNumberoDirectory) || _QueryPhoneNumberoDirectoryRead?.loading || 0 === _firstSelectedPhoneNumberoDirectory?.PhoneNumbero?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_firstSelectedPhoneNumberoDirectory) || _QueryPhoneNumberoDirectoryRead?.loading || disabled || 0 === _firstSelectedPhoneNumberoDirectory?.PhoneNumbero?.length}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidPhoneNumbero ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidPhoneNumbero ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(phoneNumbero) && !_.isEmpty(phoneNumbero) ? [phoneNumbero] : phoneNumbero)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_QueryPhoneNumberoDirectoryRead?.data?.PhoneNumberoDirectoryRead?.map?.(item => item?.id?.includes(_selectedTarget) ? item?.PhoneNumbero?.map?.(r => _.isEmpty(r?.phoneNumbero) ? void 0 : (
              <MenuItem closeOnSelect={false} key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedPhoneNumberoDirectory.length}
                  name='phoneNumbero'
                  onChange={() => {
                    // Update state of phoneNumbero's.
                    setPhoneNumbero(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r.phoneNumbero} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r.phoneNumbero} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'phoneNumbero': _data, 'phoneNumberoDirectory': phoneNumberoDirectory })

                        // Update state of phoneNumberos.
                        return _data
                      }

                      // Return updated phoneNumbero.
                      onChange({ 'phoneNumbero': [...j, `${r.phoneNumbero} (${r?.id})`], 'phoneNumberoDirectory': phoneNumberoDirectory })

                      // Update state of phoneNumberos.
                      return [...j, `${r.phoneNumbero} (${r?.id})`]
                    })
                  }}
                  isChecked={phoneNumbero.includes(`${r.phoneNumbero} (${r?.id})`)}>
                  {`${r.phoneNumbero} (${r?.id})`}
                </Checkbox>
              </MenuItem>
            )) : void 0)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={_.isEmpty(_firstSelectedPhoneNumberoDirectory) || _QueryPhoneNumberoDirectoryRead?.loading || disabled || 0 === _firstSelectedPhoneNumberoDirectory?.PhoneNumbero?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _phoneNumberoContainer = []

                /*
                 * Loop over sender id directory
                 * and get all sender id.
                 */
                for (const item of _QueryPhoneNumberoDirectoryRead.data.PhoneNumberoDirectoryRead) {
                  // Only proceed if given item is selected one.
                  if (item?.id?.includes?.(_selectedTarget)) {
                    // Loop over phoneNumbero container.
                    for (const j of item.PhoneNumbero) {
                      // Push sender id to container.
                      _phoneNumberoContainer.push(`${j.phoneNumbero} (${j.id})`)
                    }
                  }
                }

                // Update phoneNumbero.
                setPhoneNumbero(_phoneNumberoContainer)

                // Return updated phoneNumberoIds.
                onChange({ 'phoneNumbero': _phoneNumberoContainer, 'phoneNumberoDirectory': phoneNumberoDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {phoneNumbero?.length === _firstSelectedPhoneNumberoDirectory?.phoneNumbero?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={_.isEmpty(_firstSelectedPhoneNumberoDirectory) || _QueryPhoneNumberoDirectoryRead?.loading || disabled || 0 === _firstSelectedPhoneNumberoDirectory?.PhoneNumbero?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update phoneNumbero.
                setPhoneNumbero([])

                // Return updated phoneNumberoIds.
                onChange({ 'phoneNumbero': [], 'phoneNumberoDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === phoneNumbero?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'phoneNumberoDirectoryValue': PropTypes.string,
  'phoneNumberoValue': PropTypes.array,
  'inValidPhoneNumbero': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidPhoneNumberoDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
