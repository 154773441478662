/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import Debounce from 'lodash/debounce' // Npm: Debounce hook for input fields.
import * as XLSX from 'xlsx' // Npm: XLSX library.
import _ from 'underscore' // Npm: utility module.
import { toast } from 'react-hot-toast' // Npm: React toastify for notifications.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import RatePlanUpsert from 'components/RatePlanUpsert'
import RatePlanDelete from 'components/RatePlanDelete'
import FileUpload from 'components/FileUpload'
import RatePlanDropDownOptions from 'components/RatePlanDropDownOptions'
import PlanFilterOptions from 'components/PlanFilterOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RatePlanReadQuery from './__query__/index.ratePlan.read.query'
import RatePlanCreateMutation from './__mutation__/index.ratePlan.create.mutation'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Local variable.
  let _rows

  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [selectAll, setSelectAll] = React.useState(false)
  const [onlyShowSenderIdBasedRouting, setOnlyShowSenderIdBasedRouting] = React.useState(false)
  const [onlyShowPhoneNumberBasedRouting, setOnlyShowPhoneNumberBasedRouting] = React.useState(false)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [mccSearch, setMccSearchQuery] = React.useState('')
  const [mncSearch, setMncSearchQuery] = React.useState('')
  const [uploadingFile, setUploadingFile] = React.useState(false)
  const [countrySearch, setCountrySearchQuery] = React.useState('')
  const [operatorSearch, setOperatorSearchQuery] = React.useState('')
  const [selectedIds, setSelectedIds] = React.useState([])
  const [MutationRatePlanCreate] = useMutation(RatePlanCreateMutation)
  const { 'isOpen': isRatePlanCreateOpen, 'onOpen': onRatePlanCreateOpen, 'onClose': onRatePlanCreateClose } = useDisclosure()
  const { 'isOpen': isRatePlanUpdateOpen, 'onOpen': onRatePlanUpdateOpen, 'onClose': onRatePlanUpdateClose } = useDisclosure()
  const { 'isOpen': isRatePlanDeleteOpen, 'onOpen': onRatePlanDeleteOpen, 'onClose': onDeleteModalClose } = useDisclosure()
  const _QueryRatePlanRead = useQuery(RatePlanReadQuery, { 'variables': { 'rateId': passOn?.rateId, 'mccNameToSearch': mccSearch, 'mncNameToSearch': mncSearch, 'countryNameToSearch': countrySearch, 'operatorNameToSearch': operatorSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onMccInputChange = React.useCallback(Debounce(e => setMccSearchQuery(e.target.value), 800), [])
  const _onMncInputChange = React.useCallback(Debounce(e => setMncSearchQuery(e.target.value), 800), [])
  const _onCountryInputChange = React.useCallback(Debounce(e => setCountrySearchQuery(e.target.value), 800), [])
  const _onOperatorsInputChange = React.useCallback(Debounce(e => setOperatorSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  React.useEffect(() => {
    // Reset selectedIds when the data is re-fetched
    if (_QueryRatePlanRead.data) {
      // Reset states.
      setSelectedIds([])
      setSelectAll(false)
    }
  }, [isRatePlanCreateOpen, isRatePlanUpdateOpen, isRatePlanDeleteOpen, _QueryRatePlanRead.data])

  // Object assignment.
  const _HandleCheckboxChange = id => {
    // Check if id is already selected.
    if (selectedIds.includes(id)) {
      // Remove id from selected ids.
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))
    } else {
      // Add id to selected ids.
      setSelectedIds([...selectedIds, id])
    }
  }
  const _HandleSelectAll = () => {
    // Set select all to opposite of current value.
    setSelectAll(!selectAll)
    setSelectedIds(selectAll ? [] : _QueryRatePlanRead?.data?.RatePlanRead?.map(item => item.id) || [])
  }

  // Check if first load is completed.
  if (!_QueryRatePlanRead.loading && 0 < _rows?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryRatePlanRead.data?.RatePlanRead?.length || !_.every(_.pluck(_QueryRatePlanRead.data?.RatePlanRead, 'status'), i => _successFlags?.includes(i))) _isFirstLoadCompleted.current = false

  // Filter based on senderId or phoneNumber.
  if (onlyShowSenderIdBasedRouting) {
    // Filter rows.
    _rows = _QueryRatePlanRead.data?.RatePlanRead?.filter(i => !_.isEmpty(i?.SenderIdDirectory?.displayName))
  } else if (onlyShowPhoneNumberBasedRouting) {
    // Filter rows.
    _rows = _QueryRatePlanRead.data?.RatePlanRead?.filter(i => !_.isEmpty(i?.PhoneNumberoDirectory?.displayName))
  } else {
    // Filter rows.
    _rows = _QueryRatePlanRead.data?.RatePlanRead
  }

  // Return component.
  return (
    <>
      <Flex className='ratePlan base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex flexDir='column' gap='12px'>
            <Flex pb='0px' justify='space-between' overflow='hidden' flexWrap={{ 'base': 'wrap', 'md': 'wrap' }}>
              <Flex gap='12px'>
                <Tooltip label='Create new customer.' fontSize='sm'>
                  <Button
                    onClick={onRatePlanCreateOpen}
                    leftIcon={<HiPlusCircle />}
                    style={buttonStyle}>
                    New Rate Plan
                  </Button>
                </Tooltip>
                <Flex zIndex={1000}>
                  <PlanFilterOptions
                    onChange={e => {
                      // Conditional based toggling.
                      if ('SENDER_ID' === e) {
                        // Only show senderId based routing.
                        setOnlyShowSenderIdBasedRouting(!onlyShowSenderIdBasedRouting)

                        // Disable other filters.
                        setOnlyShowPhoneNumberBasedRouting(false)
                      } else if ('PHONE_NUMBER' === e) {
                        // Only show phone number based routing.
                        setOnlyShowPhoneNumberBasedRouting(!onlyShowPhoneNumberBasedRouting)

                        // Disable other filters.
                        setOnlyShowSenderIdBasedRouting(false)
                      } else {
                        // Show all.
                        setOnlyShowSenderIdBasedRouting(false)
                        setOnlyShowPhoneNumberBasedRouting(false)
                      }
                    }}
                  />
                </Flex>
              </Flex>
              <Flex gap='12px'>
                <FileUpload
                  uploadingFile={uploadingFile}
                  label='Upload RatePlan'
                  onChange={e => {
                    // Set uploadingFile to true when file upload begins
                    setUploadingFile(true)

                    // Promise all file uploads.
                    Promise.all(Array.from(e.target.files).map(file => {
                      // Const assignment.
                      const _FileReader = new FileReader()

                      // Event listener.
                      _FileReader.readAsBinaryString(file)
                      _FileReader.onload = () => {
                        // Local variable.
                        let i

                        // Const assignment.
                        const _ratePlan = []
                        const _data = _FileReader.result
                        const _workbook = XLSX.read(_data, { 'type': 'binary' })
                        const _sheetName = _workbook.SheetNames[0]
                        const _sheet = _workbook.Sheets[_sheetName]
                        const _parsedData = XLSX.utils.sheet_to_json(_sheet)
                        console.log(_parsedData)
                        // Convert this _parsedData to array of rows.
                        for (i = 0; i < _parsedData.length; i++) {
                          // Const assignment.
                          const _rate = _parsedData[i].Rate || _parsedData[i].rate || _parsedData[i]['rate*'] || _parsedData[i]['Rate*'] || _parsedData[i]['Rate'] || _parsedData[i]['rate']
                          const _mnc = _parsedData[i].MNC || _parsedData[i].Mnc || _parsedData[i].mnc || _parsedData[i]['mnc*'] || _parsedData[i]['Mnc*'] || _parsedData[i]['Mnc'] || _parsedData[i]['mnc']
                          const _mcc = _parsedData[i].MCC || _parsedData[i].Mcc || _parsedData[i].mcc || _parsedData[i]['mcc*'] || _parsedData[i]['Mcc*'] || _parsedData[i]['Mcc'] || _parsedData[i]['mcc']

                          // Push to _ratePlan.
                          _ratePlan.push({
                            'rateId': passOn?.rateId,
                            'rate': parseFloat(_rate),
                            'mcc': (_mcc ?? 0)?.toString?.(),
                            'mnc': [(_mnc ?? 0)?.toString?.()]
                          })
                        }

                        // Await all ratePlan creation.
                        MutationRatePlanCreate({
                          'variables': {
                            'input': _ratePlan
                          }
                        }).then((e) => {
                          // Set uploadingFile to false when upload completes
                          setUploadingFile(false)


                          // Refetch the data
                          toast(_.first(e?.data?.RatePlanCreate)?.message ?? 'Something is not right. try again after some time.')
                        })
                      }

                      // Return void 0
                      return void 0
                    }))
                  }}
                  accept='.xlsx, .xls, .csv'
                  disabled={uploadingFile}
                />
                <DownloadToExcel
                  cellsData={0 === _rows?.length || !_.every(_.pluck(_rows, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _rows?.map((item, __index) => ({
                    'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                    'Rate Name': item?.Rate?.displayName ?? '-',
                    'Mcc': item?.Mcc?.mcc ?? '-',
                    'Mnc': item?.Mnc?.mnc ?? '-',
                    'Country Name': item?.Mcc?.countryName ?? '-',
                    'Operators': item?.Mnc?.network ?? '-',
                    'Rate': item?.rate ? `${item?.rate} ${item?.Rate?.currency}` : '-',
                    'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                    'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD')
                  }))}
                  headersData={[
                    'S.No.',
                    'Rate Name',
                    'Mcc',
                    'Mnc',
                    'Country Name',
                    'Operators',
                    'Rate',
                    'CreatedAt',
                    'UpdatedAt'
                  ].map(i => ({ 'key': i, 'label': i }))} />
              </Flex>
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={headerStyle}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Checkbox bg='gray.100' onChange={_HandleSelectAll} isChecked={selectAll} />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Rate Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mcc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mnc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Operators
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    SenderId&apos;s Directory
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    PhoneNumber&apos;s Directory
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Rate
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mccSearch}
                      onChange={_onMccInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mncSearch}
                      onChange={_onMncInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={countrySearch}
                      onChange={_onCountryInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={operatorSearch}
                      onChange={_onOperatorsInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} />
                </Tr>
              </Tbody>
              {_QueryRatePlanRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _rows?.length || !_.every(_.pluck(_rows, 'status'), i => _successFlags?.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_rows?.map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        <Checkbox
                          bg='gray.100'
                          isChecked={selectedIds.includes(item.id)}
                          onChange={() => {
                            _HandleCheckboxChange(item.id)
                          }}></Checkbox>
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Rate?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mcc?.mcc}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mnc?.mnc ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mcc?.countryName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mnc?.network ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.SenderIdDirectory?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.PhoneNumberoDirectory?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.rate ? `${item?.rate} ${item?.Rate?.currency}` : '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <RatePlanDropDownOptions
                            ratePlanIds={[...selectedIds, item.id]}
                            ratePlanId={item.id}
                            rateId={item.Rate?.id}
                            onRatePlanUpdate={onRatePlanUpdateOpen}
                            onRatePlanDelete={onRatePlanDeleteOpen}
                            selectedIds={selectedIds}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='2xl'
          title='Rate Plan Create'
          isOpen={isRatePlanCreateOpen}
          onClose={onRatePlanCreateClose}
          isCentered={true}>
          <RatePlanUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='2xl'
          title='Rate Plan Update'
          isOpen={isRatePlanUpdateOpen}
          onClose={onRatePlanUpdateClose}
          isCentered={true}>
          <RatePlanUpsert />
        </Modal>
        <Modal
          size='md'
          title='Rate Plan Delete'
          isOpen={isRatePlanDeleteOpen}
          onClose={onDeleteModalClose}>
          <RatePlanDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryRatePlanRead.data?.RatePlanRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
