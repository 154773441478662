/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RoutePlanReadQuery($routeId: ID!, $mccNameToSearch: String, $mncNameToSearch: String, $operatorNameToSearch: String, $take: PositiveInt, $skip: Int) {
    RoutePlanRead(routeId: $routeId, mccNameToSearch: $mccNameToSearch, mncNameToSearch: $mncNameToSearch, operatorNameToSearch: $operatorNameToSearch, take: $take, skip: $skip) {
      id
      createdAt
      updatedAt
      priority,
      isPaused,
      isDestroyed,
      loadBalancerConfiguration,
      message
      status,
      _totalCount,
      VendorAccount {
        displayName
      },
      Mcc {
        createdAt,
        updatedAt,
        mcc,
        countryName,
        dialingCode
      },
      Mnc {
        createdAt,
        updatedAt,
        mnc,
        network
      }
      SenderIdDirectory {
        createdAt,
        updatedAt,
        displayName
      },
      SenderId {
        createdAt,
        updatedAt,
        senderId
      },
      PhoneNumberoDirectory {
        createdAt,
        updatedAt,
        displayName
      },
      PhoneNumbero {
        createdAt,
        updatedAt,
        phoneNumbero
      },
      Route {
        id
        displayName
      }
      VendorAccounttDirectory {
        id,
        displayName
      }
      VendorAccountt {
        id,
        VendorAccount {
          id,
          displayName
        }
      }
    }
  }
`
