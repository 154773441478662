/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Markdown from 'react-markdown' // NPM: Markdown.js library
import remarkGfm from 'remark-gfm' // NPM: Remark GFM library.
import rehypeRaw from 'rehype-raw' // NPM: Re-hype raw library.
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter' // NPM: React syntax highlighter library.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { oneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism' // NPM: React syntax highlighter library.
import { css } from 'styled-components' // NPM: Styled-components library.


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _markdown = `
  <h1 class='heading font weight900'>Documentation</h1>
  <p class='description font white weight500'><span class='font gradient position unset'>Published On : </span>22 May 2023</p>
  
  This is a comprehensive guide to the Flash api, which is used for sending and receiving SMS messages. Api could be used to send messages to a single recipient or multiple recipients. It also supports sending messages to a group of recipients. The api is designed to be easy to use and integrate with existing systems.

  First and Foremost you need to have a login token for executing your graph and you can get login token using following mutation.

  \`\`\`js
  mutation {
    AccountLoginWithEmail(email: "email@abc.com", password: "Your Password") {
      token,
      message,
      status
    }
  }
  \`\`\`

  By executing above graph with any HTTPs client you can get your token. and
  make sure to pass this token as __l-authorization__ and access token as __d-authorization__ in your header for all other upcoming request
  else all request will fail with __UNAUTHORIZED__ status.

  Each mutation, query or subscription has set of __TYPES__ attached to them allowing you to grab relevant information as you need them.

  \`\`\`js
  type Sms {
    id: ID,
    createdAt: DateTime,
    updatedAt: DateTime,
    startDate: Date,
    endDate: Date,
    submittedAt: Date,
    doneAt: Date,
    error: String,
    remoteIpAddress: IP,
    originalCopyOfMessage: String,
    destinationIpAddress: IP,
    encoding: SMS_BUILT_TYPE,
    transformedSourceAddress: String,
    receiptId: ID,
    destinationAddress: String,
    sourceAddress: String,
    dlr: String,
    CustomerRatePlan: RatePlan,
    VendorRatePlan: RatePlan,
    registeredDelivery: SMPP_REGISTERED_DELIVERY,
    count: Float,
    isUnicodeMessage: Boolean,
    messageLength: Int,
    messageChunkSize: Int,
    remoteUsername: String,
    persisted__customerrateplan: JSONObject,
    persisted__vendorrateplan: JSONObject,
    persisted__vendorsmpp: JSONObject,
    persisted__routeplan: JSONObject,
    persisted__customer: JSONObject,
    persisted__customersmpp: JSONObject,
    persisted__hlrlookup: JSONObject,
    _totalCount: Float,
    status: String,
    message: String,
  }

  enum SMS_BUILT_TYPE {
    UNICODE,
    TEXT
  }
  enum SMPP_REGISTERED_DELIVERY {
    NONE
    REQUESTED
    REQUESTED_ON_FAILURE
    REQUESTED_ON_SUCCESS
  }


  #
  # RESOLVER
  #
  extend type Query {
    SmsRead(startDate: DateTime!, endDate: DateTime!, mobileNumberToSearch: String, smsIdToSearch: String, vendorSmsIdToSearch: String, dlrStatusToSearch: SMS_STATUS, senderIdToSearch: String, take: PositiveInt, skip: Int): [Sms!]!
    SmsReadUnique(smsId: ID, receiptId: ID): Sms!
  }
  extend type Mutation {
    SmsSend(
      customerAccountId: ID!,
      destinationAddress: [String!]!, 
      registeredDelivery: SMPP_REGISTERED_DELIVERY,
      message: String!, 
      sourceAddress: String, 
      priorityFlag: PositiveInt,
      scheduledDeliveryAt: DateTime,
      repeatDeliveryAt: String,
      validityPeriod: DateTime,
      encoding: SMS_BUILT_TYPE!,
      esmClass: Int,
      type: String!,
      principalId: String,
      templateId: String,
    ): Sms! @isAuthenticated
    SmsQueue(customerAccountId: ID!, input: [SmsQueueInput!]!): [Sms!]!
  }
  \`\`\`

  __NOTE : Kindly connect with your admin for CustomerAccount Id.__\n
  You can use Apollo Client to send queries and mutations to the server. Apollo Client is a powerful GraphQL client that makes it easy to work with GraphQL APIs in your frontend applications. You can use it to send queries and mutations, cache data, and manage the state of your application.

  Here is an example of how you can use Apollo Client to send a query to the server:

  \`\`\`js
  import { ApolloClient, InMemoryCache, gql } from '@apollo/client'

  // Create a new Apollo Client
  const _Client = new ApolloClient({ uri: '{hostname}/v1/graphql', cache: new InMemoryCache() })

  // Define the query
  const _query = gql\`
    query GetSms {
      SmsRead(startDate: "1994-05-02", endDate: "1994-05-26", take: 10, skip: 0) {
        id
        createdAt
        updatedAt
        startDate
        endDate
        submittedAt
        doneAt
        error
        remoteIpAddress
        originalCopyOfMessage
        destinationIpAddress,
        message,
        status
      }
    }
    \`

  // Send the query
  _Client.query({ 'query': _query }).then(r => console.log(r))
  \`\`\`

  You can expect the response to be an array of Sms objects. Each Sms object will contain the fields specified in the query. You can use this data to update your UI or perform other operations in your application.

  Also not that each query and mutation has respective message and status for each query
  which gets executed and on successful response. you can expect __TRUTHY VALUES__ and failed action with non __TRUTHY VALUES__.

  All responses are with status and message flag. which you can use for debugging and error handling.
  \`\`\`js
  // Const assignment.
  const _TruthyStatus = ['CREATE_SUCCESSFUL', 'UPDATED_SUCCESSFUL', 'DELETE_SUCCESSFUL', 'UPSERT_SUCCESSFUL', 'READ_SUCCESSFUL']

  {
    "message": "Some request failed",
    "status": "Anything except _TruthyStatus"
  }
  \`\`\`
  
  Now, with execution of mutation and subscription you can expect the same flow as of query.

  I hope you enjoy it ❤️
  `
  const _styleMarkdown = css({
    'pre pre': {
      'background': 'transparent',
      'background-color': '#F8F8F8',
      'borderRadius': 2.5
    },
    'code': {
      'backgroundColor': 'transparent'
    },
    'pre code': {
      'background': 'transparent'
    }
  })

  // Component assignment.
  const Pre = ({ children }) => (
    <pre className='codeWrapper'>
      {children}
    </pre>
  )

  // Return component.
  return (
    <Flex
      my='0'
      borderRadius='20px'
      bg='white'
      gap='12px'
      w='100%'
      h='100%'
      className='apiDocumentation'
      overflowY='auto'>
      <Markdown
        className={['markDownContainer'].join(' ')}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        lineNumber={true}
        css={_styleMarkdown}
        components={{
          'pre': Pre,
          code({ inline, className, children, ...props }) {
            // Get language.
            const _match = (/language-(\w+)/u).exec(className || '')

            // Return component.
            return !inline && _match ? (
              <SyntaxHighlighter
                style={oneLight}
                language={_match[1]}
                {...props}>{String(children).replace(/\n$/u, '')}</SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          }
        }}>{_markdown}</Markdown>
    </Flex>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'children': PropTypes.node
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)



