/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import Debounce from 'lodash/debounce' // Npm: Lodash debounce.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import {
  Checkbox,
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSearchSelect, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountDeleteMutation from './__mutation__/index.vendorAccount.delete.mutation'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'
import RateReadQuery from './__query__/index.rate.read.query'
import VendorAccountUpdateMutation from './__mutation__/index.vendorAccount.update.mutation'
import VendorAccountCreateMutation from './__mutation__/index.vendorAccount.create.mutation'
import AttachRateMutation from './__mutation__/index.vendorAccount.attachRate.mutation'
import VendorAccountAttachSmppMutation from './__mutation__/index.vendorAccount.attachSmpp.mutation'
import SmppUpdateMutation from './__mutation__/index.smpp.update.mutation'
import SmppCreateMutation from './__mutation__/index.smpp.create.mutation'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  isCreateOnly,
  onClose,
  passOn
}) => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [rate, setRate] = React.useState('')
  const [searchQuery, setSearchQuery] = React.useState('')
  const [MutationAttachRate] = useMutation(AttachRateMutation)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationVendorAccountCreate, MutationVendorAccountCreateResponse] = useMutation(VendorAccountCreateMutation)
  const [MutationVendorAccountUpdate, MutationVendorAccountUpdateResponse] = useMutation(VendorAccountUpdateMutation)
  const [QueryVendorAccountRead, QueryVendorAccountReadResponse] = useLazyQuery(VendorAccountReadQuery)
  const [MutationVendorAccountDelete] = useMutation(VendorAccountDeleteMutation)
  const [MutationSmppCreate, MutationSmppCreateResponse] = useMutation(SmppCreateMutation)
  const [MutationSmppUpdate, MutationSmppUpdateResponse] = useMutation(SmppUpdateMutation)
  const [MutationAttachSmpp, MutationAttachSmppResponse] = useMutation(VendorAccountAttachSmppMutation)
  const _RateReadQuery = useQuery(RateReadQuery, { 'variables': { 'search': searchQuery, 'type': 'TERMINATION', 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'ip': JoiBrowser.string().max(45).ip({ 'version': ['ipv4', 'ipv6', 'ipvfuture'], 'cidr': 'optional' }).required(),
      'username': JoiBrowser.string().required(),
      'password': JoiBrowser.string().required(),
      'txPort': JoiBrowser.number().required(),
      'rxPort': JoiBrowser.number().required(),
      'isSticky': JoiBrowser.boolean().optional(),
      'sessionTimeoutInterval': JoiBrowser.number().required(),
      'sessionAllowed': JoiBrowser.number().greater(0).less(11).optional(),
      'tps': JoiBrowser.number().greater(0).optional(),
      'enquireLinkInterval': JoiBrowser.number().required(),
      'maxReconnectAttempts': JoiBrowser.number().optional(),
      'reconnectBackoffFactor': JoiBrowser.number().optional(),
      'initialReconnectDelay': JoiBrowser.number().optional(),
      'sourceAddressTon': JoiBrowser.number().optional(),
      'sourceAddressNpi': JoiBrowser.number().optional(),
      'destinationAddressTon': JoiBrowser.number().optional(),
      'destinationAddressNpi': JoiBrowser.number().optional(),
      'trunkType': JoiBrowser.string().required(),
      'debuggingLevel': JoiBrowser.any().required(),
      'rate': JoiBrowser.string().optional(),
      'route': JoiBrowser.string().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = { ..._.pick(_formDataRef.current, _.identity), 'isSticky': _formDataRef.current?.isSticky }

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create VendorAccount for given Vendor.
    const _MutationVendorAccount = await [isCreateOnly ? MutationVendorAccountCreate : MutationVendorAccountUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'vendorId' : 'vendorAccountId']: isCreateOnly ? passOn?.vendorId : passOn?.vendorAccountId,
        'displayName': _formDataRef?.current?.displayName
      }
    })

    // If creating or updating vendor account caught an exception then report failure.
    if (_MutationVendorAccount instanceof Error) return _MutationVendorAccount

    // Execute update mutation.
    const _MutationSmpp = await [isCreateOnly ? MutationSmppCreate : MutationSmppUpdate]?.[0]({
      'variables': {
        'smppId': isCreateOnly || _.isEmpty(_MutationVendorAccount?.data?.VendorAccountUpdate?.Smpp) ? 'UN_KNOWN' : _MutationVendorAccount?.data?.VendorAccountUpdate?.Smpp?.id,
        'displayName': _formDataRef?.current?.displayName,
        'ip': _formDataRef?.current?.ip,
        'username': _formDataRef?.current?.username,
        'password': _formDataRef?.current?.password,
        'txPort': _formDataRef?.current?.txPort,
        'rxPort': _formDataRef?.current?.rxPort,
        'sessionTimeoutInterval': _formDataRef?.current?.sessionTimeoutInterval,
        'sessionAllowed': _formDataRef?.current?.sessionAllowed,
        'tps': _formDataRef?.current?.tps,
        'enquireLinkInterval': _formDataRef?.current?.enquireLinkInterval,
        'maxReconnectAttempts': _formDataRef?.current?.maxReconnectAttempts,
        'reconnectBackoffFactor': _formDataRef?.current?.reconnectBackoffFactor,
        'initialReconnectDelay': _formDataRef?.current?.initialReconnectDelay,
        'sourceAddressTon': _formDataRef?.current?.sourceAddressTon,
        'sourceAddressNpi': _formDataRef?.current?.sourceAddressNpi,
        'destinationAddressTon': _formDataRef?.current?.destinationAddressTon,
        'destinationAddressNpi': _formDataRef?.current?.destinationAddressNpi,
        'trunkType': _formDataRef?.current?.trunkType,
        'rate': _formDataRef?.current?.rate,
        'debuggingLevel': _formDataRef?.current?.debuggingLevel,
        'isSticky': _formDataRef?.current?.isSticky
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationSmpp instanceof Error) return _MutationSmpp
    if (isCreateOnly && 'HOST_DOWN_OR_UNREACHABLE' === _MutationSmpp?.data?.SmppCreate?.status) return MutationVendorAccountDelete({ 'variables': { 'vendorAccountId': _MutationVendorAccount?.data.VendorAccountCreate?.id } })

    // Object assignment.
    const _AttachRateAndRouteToVendor = async () => {
      // Local variable.
      let _MutationAttachRateToVendor

      // Only proceed if rate has data.
      if (!_.isEmpty(rate)) {
        // Local variable.
        let _rate

        // Variable assignment.
        _rate = rate

        // Only update rate if it is available.
        if (_rate?.includes('(') || _rate?.includes(')')) _rate = rate?.split('(')?.[1].split(')')?.[0]

        // Bind Rate to given vendor.
        _MutationAttachRateToVendor = await MutationAttachRate({
          'variables': {
            'vendorAccountId': _MutationVendorAccount?.data[isCreateOnly ? 'VendorAccountCreate' : 'VendorAccountUpdate']?.id,
            'rateId': _rate
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationAttachRateToVendor instanceof Error) {
          // Report failure.
          return _MutationAttachRateToVendor
        }

        // Style Guide.
        toast(_MutationAttachRateToVendor?.data?.VendorAccountAttachRate?.message)
      }

      // Report failure if both route and rate are empty.
      if (!_.isEmpty(_MutationAttachRateToVendor) && 'UPDATE_SUCCESSFUL' === _MutationAttachRateToVendor?.data?.VendorAccountAttachRate?.status) onClose?.()

      // Report void.
      return void 0
    }

    // Style Guide.
    toast(_MutationSmpp?.data?.SmppCreate?.message ?? _MutationSmpp?.data?.SmppUpdate?.message)

    // Attach route and rate to given vendor.
    await _AttachRateAndRouteToVendor()

    /*
     * Only execute attach query if its about
     * creation of new smpp.
     */
    if ('CREATE_SUCCESSFUL' === _MutationSmpp?.data?.SmppCreate?.status) {
      // Attach given vendor with smpp.
      const _MutationAttachSmpp = await MutationAttachSmpp({ 'variables': { 'vendorAccountId': _MutationVendorAccount?.data[isCreateOnly ? 'VendorAccountCreate' : 'VendorAccountUpdate']?.id, 'smppId': _MutationSmpp?.data?.SmppCreate?.id } })

      // If mutation caught an exception then report failure.
      if (_MutationAttachSmpp instanceof Error) return _MutationAttachSmpp

      // Only close if attach is successful.
      if ('UPDATE_SUCCESSFUL' === _MutationAttachSmpp?.data?.VendorAccountAttachSmpp?.status) return onClose?.()
    }

    // Only close if attach is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationSmpp?.data?.SmppUpdate?.status) return onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorAccountReadQuery = await QueryVendorAccountRead({ 'variables': { 'vendorAccountId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorAccountId } })

      // If query caught an exception then report failure.
      if (_QueryVendorAccountReadQuery instanceof Error) return _QueryVendorAccountReadQuery

      // Const assignment.
      const _vendorAccountRead = _.first(_QueryVendorAccountReadQuery?.data?.VendorAccountRead)

      /*
       * If details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_vendorAccountRead)) {
        // Update form data.
        _formDataRef.current = {
          'displayName': _vendorAccountRead?.Smpp?.displayName,
          'ip': _vendorAccountRead?.Smpp?.ip,
          'username': _vendorAccountRead?.Smpp?.username,
          'password': _vendorAccountRead?.Smpp?.password,
          'txPort': _vendorAccountRead?.Smpp?.txPort,
          'rxPort': _vendorAccountRead?.Smpp?.rxPort,
          'sessionTimeoutInterval': _vendorAccountRead?.Smpp?.sessionTimeoutInterval,
          'sessionAllowed': _vendorAccountRead?.Smpp?.sessionAllowed,
          'tps': _vendorAccountRead?.Smpp?.tps,
          'enquireLinkInterval': _vendorAccountRead?.Smpp?.enquireLinkInterval,
          'maxReconnectAttempts': _vendorAccountRead?.Smpp?.maxReconnectAttempts,
          'reconnectBackoffFactor': _vendorAccountRead?.Smpp?.reconnectBackoffFactor,
          'initialReconnectDelay': _vendorAccountRead?.Smpp?.initialReconnectDelay,
          'sourceAddressTon': _vendorAccountRead?.Smpp?.sourceAddressTon,
          'sourceAddressNpi': _vendorAccountRead?.Smpp?.sourceAddressNpi,
          'destinationAddressTon': _vendorAccountRead?.Smpp?.destinationAddressTon,
          'destinationAddressNpi': _vendorAccountRead?.Smpp?.destinationAddressNpi,
          'trunkType': _vendorAccountRead?.Smpp?.trunkType,
          'debuggingLevel': _vendorAccountRead?.Smpp?.debuggingLevel,
          'isSticky': _vendorAccountRead?.Smpp?.isSticky,
          'rate': _vendorAccountRead?.Rate?.displayName ? `${_vendorAccountRead?.Rate?.displayName} (${_vendorAccountRead?.Rate?.id})` : void 0
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorAccountCreateResponse.loading || MutationVendorAccountUpdateResponse.loading || MutationSmppCreateResponse.loading || MutationSmppUpdateResponse.loading || MutationAttachSmppResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryVendorAccountReadResponse.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex w='100%' gap='22px' flexDir='column'>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='displayName'
            label='Display name'
            placeholder='e.g. "Rocking Smpp"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('displayName')}
            error={error}
            data={_formDataRef?.current?.displayName}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='ip'
            label='ip'
            placeholder='e.g. "0.0.0.0"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('ip')}
            data={_formDataRef?.current?.ip}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='username'
            label='SystemId'
            placeholder='e.g. "RockSmpp"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('username')}
            data={_formDataRef?.current?.username}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='password'
            label='Password'
            placeholder='e.g. "*******"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('password')}
            data={_formDataRef?.current?.password}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='txPort'
            label='Transmitter Port'
            placeholder='e.g. "2775"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('txPort')}
            data={_formDataRef?.current?.txPort}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='rxPort'
            label='Receiver Port'
            placeholder='e.g. "2775"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('rxPort')}
            data={_formDataRef?.current?.rxPort}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='sessionTimeoutInterval'
            label='Response Time ( in Seconds )'
            placeholder='e.g. "3 is 3Seconds"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Make sure that value is greater then 0.
              if (0 >= Number(value)) return setError('sessionTimeoutInterval must be greater then 0')

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value) * 1000
              }

              // Report void.
              return void 0
            }}
            error={error}
            isInvalid={error?.includes('sessionTimeoutInterval')}
            data={0 < _formDataRef?.current?.sessionTimeoutInterval ? _formDataRef?.current?.sessionTimeoutInterval / 1000 : _formDataRef?.current?.sessionTimeoutInterval}
          />
          <MemoizedSelect
            disabled={_isInputDisabled}
            name='sessionAllowed'
            label='Session Allowed'
            placeholder='e.g. "2"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            options={[
              1,
              2,
              3,
              4,
              5
            ].map(i => i)}
            isInvalid={error?.includes('sessionAllowed')}
            data={_formDataRef?.current?.sessionAllowed}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='tps'
            label='Submit Speed Per Second'
            placeholder='e.g. "1000"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: 1000 < Number(value ?? 0) ? 1000 : Number(value ?? 0)
              }
            }}
            error={error}
            isInvalid={error?.includes('tps')}
            data={_formDataRef?.current?.tps}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='enquireLinkInterval'
            label='Enquire link ( in Seconds )'
            placeholder='e.g. "60 is 60Seconds"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Make sure that value is greater then 0.
              if (0 >= Number(value)) return setError('enquireLinkInterval must be greater then 0')

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value ?? 0) * 1000
              }

              // Report void.
              return void 0
            }}
            error={error}
            isInvalid={error?.includes('enquireLinkInterval')}
            data={1000 < _formDataRef?.current?.enquireLinkInterval ? _formDataRef?.current?.enquireLinkInterval / 1000 : _formDataRef?.current?.enquireLinkInterval}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='maxReconnectAttempts'
            label='Max Reconnect'
            placeholder='e.g. "0"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('maxReconnectAttempts')}
            data={_formDataRef?.current?.maxReconnectAttempts}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='reconnectBackoffFactor'
            label='RD ( in Seconds )'
            placeholder='e.g. "2 is 2Seconds"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Make sure that value is greater then 0.
              if (0 >= Number(value)) return setError('reconnectBackoffFactor must be greater then 0')

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value) * 1000
              }

              // Report void.
              return void 0
            }}
            error={error}
            isInvalid={error?.includes('reconnectBackoffFactor')}
            data={0 < _formDataRef?.current?.reconnectBackoffFactor ? _formDataRef?.current?.reconnectBackoffFactor / 1000 : _formDataRef?.current?.reconnectBackoffFactor}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='initialReconnectDelay'
            label='IRD ( in Seconds )'
            placeholder='e.g. "1 is 1Second"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Make sure that value is greater then 0.
              if (0 >= Number(value)) return setError('initialReconnectDelay must be greater then 0')

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }

              // Report void.
              return void 0
            }}
            error={error}
            isInvalid={error?.includes('initialReconnectDelay')}
            data={0 < _formDataRef?.current?.initialReconnectDelay ? _formDataRef?.current?.initialReconnectDelay / 1000 : _formDataRef?.current?.initialReconnectDelay}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='sourceAddressTon'
            label='SourceAddress ton'
            placeholder='e.g. "0"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('sourceAddressTon')}
            data={_formDataRef?.current?.sourceAddressTon}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='sourceAddressNpi'
            label='SourceAddress npi'
            placeholder='e.g. "0"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('sourceAddressNpi')}
            data={_formDataRef?.current?.sourceAddressNpi}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='destinationAddressTon'
            label='DestinationAddress Ton'
            placeholder='e.g. "1"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('destinationAddressTon')}
            data={_formDataRef?.current?.destinationAddressTon}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='destinationAddressNpi'
            label='DestinationAddress npi'
            placeholder='e.g. "1"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: Number(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('destinationAddressNpi')}
            data={_formDataRef?.current?.destinationAddressNpi}
          />
        </Flex>
        <Flex w='100%' flexDir='column' gap='22px'>
          <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
            <MemoizedSelect
              disabled={_isInputDisabled}
              isRequired={true}
              name='trunkType'
              label='Route'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              placeholder='e.g. "DIRECT"'
              options={Object.React.App.enums.TRUNK_TYPE.enums?.map(i => i.key)}
              isInvalid={error?.includes('trunkType')}
              data={_formDataRef?.current?.trunkType}
            />
            <MemoizedSelect
              disabled={_isInputDisabled}
              isRequired={true}
              name='debuggingLevel'
              label='Error Code Level'
              placeholder='e.g. "ERROR"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              options={Object.React.App.enums.SMPP_DEBUGGING_LEVEL.enums?.map(i => i.key)}
              isInvalid={error?.includes('debuggingLevel')}
              data={_formDataRef?.current?.debuggingLevel}
            />
          </Flex>
          <MemoizedSearchSelect
            disabled={_isInputDisabled}
            label='Assign Rate'
            data={_formDataRef?.current?.rate}
            placeholder='e.g. "Awesome Rate"'
            onChange={_onSearchInputChange}
            onSelect={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }

              // Update rate.
              setRate(value)
            }}
            options={_RateReadQuery?.data?.RateRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
          />
        </Flex>
      </Flex>
      <Flex
        w='100%'
        mt='22px'
        color='rgba(43, 54, 116, 1)'
        fontWeight='700'
        fontSize='20px'
        lineHeight='24px'
        fontFamily='DM Sans'
        justifyContent='space-between'
        flexDir={_isCurrentViewMobile ? 'column' : 'row'}
        gap='22px'>
        <Flex flexDir='column'>
          <Flex>
            <Checkbox
              disabled={_isInputDisabled}
              colorScheme='red'
              color='red.500'
              name='isSticky'
              isChecked={_formDataRef?.current?.isSticky}
              onChange={({ target }) => {
                // Over spreading.
                const { name, checked } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: checked
                }

                // Update state.
                setForceReRender(String.random(9))
              }}>
              Sticky Session ?
            </Checkbox>
          </Flex>
          <Text fontSize='14px' fontWeight='500' color='gray.500'>Do connection reconnect if <span style={{ 'fontWeight': 900 }}>SMPP</span> gets <span style={{ 'fontWeight': 900 }}>Closed</span> due to some reason.</Text>
        </Flex>
        <SubmitButton
          mt={0}
          disabled={_isInputDisabled}
          onSubmit={_SubmitForm}
          defaultText={isCreateOnly ? 'Create Smpp' : 'Update Smpp'}
          isLoading={_isLoading} />
      </Flex>
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
