/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query VendorAccounttDirectoryReadQuery($take: PositiveInt!, $skip: Int!) {
    VendorAccounttDirectoryRead(take: $take, skip: $skip) {
      id,
      displayName,
      message,
      status,
      VendorAccountt {
        id,
        displayName,
        VendorAccount {
          id,
          displayName,
          Vendor {
            id,
            displayName
          }
        }
      }
    }
  }
`
