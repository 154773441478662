/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccounttUpsertMutation($vendorAccounttDirectoryId: ID, $vendorAccountt: [ID!]!, $displayName: String!) {
  VendorAccounttUpsert(vendorAccounttDirectoryId: $vendorAccounttDirectoryId, vendorAccountt: $vendorAccountt, displayName: $displayName) {
    id
    message
    status
  }
}
`
