/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import { connect } from 'react-redux' // Npm: React Redux library.
import {
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import Pagination from 'components/Pagination'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * OBJECTS
 */
const Index = ({ skipDifference, setSkipDifference, totalCount, onPageChange, platform, ...props }) => {
  // Const assignment.
  const _customPage = {
    '1k': 1000,
    '2k': 2000,
    '3k': 3000,
    '4k': 4000,
    '5k': 5000
  }

  // Variable assignment.
  totalCount = totalCount ?? 0

  // Hook assignment.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _isCurrentViewTablet = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': false, 'xl': false, 'sm': false, 'xs': false })

  // Return component.
  return (
    <Flex alignItems='center' w='100%' justifyContent='space-between' ml={_isCurrentViewMobile ? 'auto' : 'none'} mt='22px' {...props}>
      <Flex w='100%' justifyContent='space-between' alignItems='baseline'>
        <Flex
          width='100%'
          flexDir='column'>
          <Flex
            position='relative'
            top='5px'
            width='100%'
            fontSize={{ 'base': 'md', 'md': 'large' }}
            flexWrap='wrap'
            className='paginationPageSizeSelect'
            alignItems='center'>
            <Text>
              <div style={{ 'display': 'flex', 'alignItems': 'center' }}>
                <span>{_isCurrentViewMobile ? 'Showing' : 'Show'}</span>
                {
                  <MemoizedSelect
                    mx='6px'
                    color='red'
                    _focus={{ 'border': 'none' }}
                    width={22}
                    options={[
                      skipDifference,
                      skipDifference * 2,
                      skipDifference * 3,
                      skipDifference * 4,
                      skipDifference * 5,
                      skipDifference * 6,
                      ...Object.keys(_customPage)
                    ]}
                    onChange={i => setSkipDifference(_customPage[i?.target?.value] ?? i?.target?.value)}
                  />
                }
                {_isCurrentViewMobile ? 'Records' : 'Records,'}&nbsp;&nbsp;
              </div>
            </Text>
            {
              _isCurrentViewMobile ? void 0 : (
                <>
                  <Text>
                    Total{' '}
                    <span className='paginationPageSizeSelectTotalCount'>
                      {totalCount}
                    </span>
                  </Text>
                  Records Found.
                </>
              )
            }
          </Flex>
          <Text
            position='relative'
            top='-5px'
            color='brand.500'
            fontSize='sm'
            textTransform='uppercase'
            fontWeight={700}>
            {platform?.copyright?.slice(0, _isCurrentViewTablet ? 100 : _isCurrentViewMobile ? 20 : 100)}
          </Text>
        </Flex>
      </Flex>
      <Pagination
        itemsPerPage={skipDifference}
        totalCount={totalCount}
        onPageChange={onPageChange}
      />
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onPageChange': PropTypes.func,
  'totalCount': PropTypes.number,
  'itemsPerPage': PropTypes.number,
  'skipDifference': PropTypes.number,
  'setSkipDifference': PropTypes.func,
  'platform': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn, 'platform': __state.Platform })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
