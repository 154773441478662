/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import React from 'react' // Npm: react.js library.
import _ from 'underscore' // Npm: Utility library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Thead, Tr, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiChatBubbleOvalLeftEllipsis, HiServer } from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import VendorAccountSelector from 'components/VendorAccountSelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import TestReadQuery from './__query__/index.test.read.query'
import TestVendorAccountMutation from './__mutation__/index.test.vendorAccount.mutation'


/*
 * STYLES
 */
import './index.css'
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [error, setError] = React.useState('')
  const [vendorAccountId, setVendorAccountId] = React.useState('')
  const [testInProgress, setTestInProgress] = React.useState(false)
  const [MutationTestVendorAccount, MutationTestVendorAccountResponse] = useMutation(TestVendorAccountMutation)
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _formDataRef = React.useRef({})
  const _QueryTestRead = useQuery(TestReadQuery, { 'variables': { 'vendorAccountId': vendorAccountId && (vendorAccountId?.includes('(') || vendorAccountId?.includes(')')) ? vendorAccountId.split('(')[1].split(')')[0] : vendorAccountId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _vendorAccountId

    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Conditionally fix for vendorAccountId.
    if (_formDataRef?.current?.vendorAccountId && (_formDataRef?.current?.vendorAccountId?.includes('(') || _formDataRef?.current?.vendorAccountId?.includes(')'))) _vendorAccountId = _formDataRef?.current?.vendorAccountId.split('(')[1].split(')')[0]

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'vendorAccountId': JoiBrowser.string().required(),
      'encoding': JoiBrowser.string().required(),
      'sourceAddress': JoiBrowser.string().required(),
      'displayName': JoiBrowser.string().required(),
      'shortMessage': JoiBrowser.string().required(),
      'destinationAddress': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Set test in progress.
    setTestInProgress(true)

    // Execute update mutation.
    const _MutationTestVendorAccount = await MutationTestVendorAccount({ 'variables': { ..._formDataRef.current, 'vendorAccountId': _vendorAccountId } })

    // Set test in progress.
    setTestInProgress(false)

    // Refetch test read query.
    _QueryTestRead.refetch()

    // If mutation caught an exception then report failure.
    if (_MutationTestVendorAccount instanceof Error) return _MutationTestVendorAccount

    // Style guide.
    toast(_MutationTestVendorAccount?.data?.TestVendorAccount?.message)

    // Report void.
    return void 0
  }

  // Data assignment.
  const _isInputDisabled = MutationTestVendorAccountResponse.loading

  // Return component.
  return (
    <>
      <Flex
        my='0'
        w='100%'
        borderRadius='20px'
        bg='white'
        p='15px'
        h='100%'
        overflowY='auto'
        gap='12px'
        flexDir='column'
        className='routeTesting'
        color='#1B2559'
        fontSize={['clamp(13px, 1.5vw, 16px)']}
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
        <Text as='h2' fontSize='xl' color='#393A41'>Route Testing</Text>
        <Text w={_isCurrentViewMobile ? '100%' : '65%'} as='p' mt='0' my='12px' color='gray.500' gap='6px' className='heading' fontSize='17px'>Select <Text as='span' cursor='pointer' fontWeight={700} color='brand.500'><HiServer size={21} />Vendor Account</Text>&nbsp;&nbsp;and send <Text cursor='pointer' as='span' fontWeight={700} color='purple'><HiChatBubbleOvalLeftEllipsis size={22} />Message</Text> to test how given vendor account or route is behaving on deliveries.&nbsp;&nbsp;</Text>
        <Flex w='100%' flexDir='column'>
          <Flex w='100%' gap='22px' justifyContent='space-between' flexDirection={{ 'base': 'column', 'md': 'row' }}>
            <VendorAccountSelector
              name='vendorAccountId'
              label='Vendor'
              bg='gray.100'
              _focus={{ 'bg': 'gray.200' }}
              placeholder='e.g. Spam Vendor'
              error={error}
              isInvalid={error?.includes('vendorAccountId')}
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }

                // Update vendorAccount.
                setVendorAccountId(value)
              }}
              disabled={_isInputDisabled}
              isRequired />
            <MemoizedSelect
              name='encoding'
              label='Encoding'
              bg='gray.100'
              _focus={{ 'bg': 'gray.200' }}
              placeholder='e.g. TEXT'
              disabled={_isInputDisabled}
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              options={Object.React.App.enums.SMS_BUILT_TYPE.enums.map(i => i.key)}
              isInvalid={error?.includes('encoding')}
              isRequired />
            <MemoizedInput
              name='sourceAddress'
              label='Sender Id'
              bg='gray.100'
              _focus={{ 'bg': 'gray.200' }}
              placeholder='e.g. SPAMM'
              disabled={_isInputDisabled}
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isInvalid={error?.includes('sourceAddress')}
              isRequired />
            <MemoizedInput
              name='displayName'
              label='Name'
              bg='gray.100'
              placeholder='e.g. Route Test'
              _focus={{ 'bg': 'gray.200' }}
              disabled={_isInputDisabled}
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isInvalid={error?.includes('displayName')}
              isRequired />
          </Flex>
          <Flex w='100%' mt='22px' gap='22px' justifyContent='space-between' flexDirection={{ 'base': 'column', 'md': 'row' }} alignItems='flex-end'>
            <MemoizedInput
              name='shortMessage'
              label='Message'
              bg='gray.100'
              _focus={{ 'bg': 'gray.200' }}
              disabled={_isInputDisabled}
              placeholder='e.g. something is fishy on this route.'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isInvalid={error?.includes('shortMessage')}
              isRequired />
            <MemoizedInput
              name='destinationAddress'
              label='Destination Address'
              bg='gray.100'
              _focus={{ 'bg': 'gray.200' }}
              placeholder='e.g. 8826668515'
              disabled={_isInputDisabled}
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isInvalid={error?.includes('destinationAddress')}
              isRequired />
            <Button bg='brand.500' _hover={{ 'bg': 'brand.600' }} color='white' borderRadius='12px' h='38px' w={_isCurrentViewMobile ? '100%' : '160px'} fontSize='15px' onClick={_SubmitForm}>{testInProgress ? 'WAIT' : 'TEST'}</Button>
          </Flex>
        </Flex>
        <Text mt='22px' as='h3' fontSize='md' color='gray.700' fontWeight={600}>Test Results</Text>
        <TableContainer
          flex={1}
          display='flex'
          minH='500px'
          borderRadius='15px'
          outline='1px solid #C5CFE8'>
          <Table variant='simple' size='xs'>
            <Thead ref={_tableHeaderHeightRef}>
              <Tr style={headerStyle}>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  S.No.
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Name
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Destination Address
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Source Address
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Message
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Status
                </Td>
                <Td
                  style={rowStyle}>
                  Delay ( in seconds )
                </Td>
              </Tr>
            </Thead>
            {_QueryTestRead.loading && !_isFirstLoadCompleted.current ? (
              <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : !_isFirstLoadCompleted.current && (0 === _QueryTestRead.data?.TestRead?.length || !_.every(_.pluck(_QueryTestRead.data?.TestRead, 'status'), i => _successFlags?.includes(i))) ? (
              <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : (
              <Tbody style={cellStyle}>
                {_QueryTestRead.data?.TestRead?.map((item, __index) => (
                  <Tr className={_.isEmpty(item?.SenderIdDirectory?.displayName) ? _.isEmpty(item?.PhoneNumberoDirectory?.displayName) ? void 0 : 'senderIdBasedPlan' : (_.isEmpty(item?.PhoneNumberoDirectory?.displayName) ? 'phoneNumberBasedPlan' : 'bothPlan')} key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${__index + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.response?.destinationAddress ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.response?.sourceAddress ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.response?.shortMessage ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {'0' === JSON.stringify(item?.response?.command_status) ? 'SUCCESSFUL' : (JSON.stringify(item?.response?.command_status) ?? '-')}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {item?.response?.delay ?? '-'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index



